import React, { useEffect, useState } from "react";
import { View } from "@adobe/react-spectrum";
import { useSelector } from "react-redux";
import SearchContainer from "../components/search/SearchContainer";
import SearchResultTable from "../components/search/SearchResultTable";
import { RootState } from "../store/store";
import "../styles/Pages/SearchPage.scss";
const SearchPage = () => {
  const [caseNumberOrIMSOrgID, setCaseNumberOrIMSOrgID] = useState("");
  const searchText = useSelector((state: RootState) => state.case.searchText);
  document.title = "Search";
  useEffect(() => {
    if (searchText !== undefined && searchText !== "") {
      setCaseNumberOrIMSOrgID(searchText);
    }
  }, [searchText]);

  const handleSearchText = (searchQuery: string) => {
    setCaseNumberOrIMSOrgID(searchQuery);
  };

  return (
    <View UNSAFE_className="search_wrapper">
      {!!caseNumberOrIMSOrgID ? (
        <View paddingBottom={55}>
          <SearchResultTable searchText={searchText} />
        </View>
      ) : (
        <SearchContainer
          handleSearch={(searchText: string) =>
            handleSearchText(caseNumberOrIMSOrgID)
          }
        />
      )}
    </View>
  );
};
export default SearchPage;
