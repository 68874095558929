import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export interface HealthCheckResponse {
  status: string;
}

export const healthCheckApi = createApi({
  reducerPath: "healthCheckApi",
  baseQuery: fetchBaseQuery({
    // @ts-ignore String is not undefined.
    baseUrl: window.env.REACT_APP_API_BASE.replace("/v1/", "/"),
    timeout: 10000,
  }),
  endpoints: (builder) => ({
    getHealthCheck: builder.query<HealthCheckResponse, void>({
      query: () => `health`,
    }),
  }),
});

export const { useGetHealthCheckQuery } = healthCheckApi;
