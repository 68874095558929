import { IMenuItem } from "../../types/ISideNavbar";

const MarketoMenuData: IMenuItem[] = [
  {
    Solution: "Marketo",
    LinkName: "Ghost Login",
    LinkURL:
      "https://app.marketo.com/homepage/malogin?redirect_uri=https%3A%2F%2Fmarketopowertools-or2.cloud.adobe.io%2Fcallback&prefix={marketoPrefix}&caseNumber={caseId}&supportCaseContact={requestor_email}",
    Contextual: "No",
    ContextualURLParameters: "",
    LinkDescription: "Ghost Authentication",
    PointOfContact: "Adam Boschma",
    DocumentationLink:
      "https://wiki.corp.adobe.com/display/MKTOENG/Marketo+Power+Tools",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Marketo",
    LinkName: "Power Tools",
    LinkURL: "https://marketopowertools-or2.cloud.adobe.io/",
    Contextual: "No",
    ContextualURLParameters: "",
    LinkDescription: "Homepage for Marketo Support tools",
    PointOfContact: "Adam Boschma",
    DocumentationLink:
      "https://wiki.corp.adobe.com/display/MKTOENG/Marketo+Power+Tools",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Marketo",
    LinkName: "Marketo Splunk",
    LinkURL:
      "https://siteanalytics.marketo.com/en-US/account/login?return_to=%2Fen-US%2Fapp%2Fengineering%2Fhome",
    Contextual: "No",
    ContextualURLParameters: "",
    LinkDescription: "Marketo Splunk logs not yet migrated to Adobe Splunk",
    PointOfContact: "",
    DocumentationLink: "",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Marketo",
    LinkName: "Adobe Splunk",
    LinkURL: "https://splunk.or1.adobe.net/",
    Contextual: "No",
    ContextualURLParameters: "",
    LinkDescription: "Adobe Splunk",
    PointOfContact: "",
    DocumentationLink: "",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Marketo",
    LinkName: "Appview",
    LinkURL: "https://appview.marketo.com/",
    Contextual: "No",
    ContextualURLParameters: "",
    LinkDescription: "Database with all customer environment view",
    PointOfContact: "",
    DocumentationLink: "",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Marketo",
    LinkName: "Columbus",
    LinkURL: "https://columbus.corp.adobe.com/",
    Contextual: "No",
    ContextualURLParameters: "",
    LinkDescription: "",
    PointOfContact: "",
    DocumentationLink: "",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Marketo",
    LinkName: "ABO / Login-As",
    LinkURL:
      "https://abo.services.adobe.com/customers/search?query={requestor_email}",
    Contextual: "No",
    ContextualURLParameters: "",
    LinkDescription: "",
    PointOfContact: "",
    DocumentationLink: "",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Marketo",
    LinkName: "Panorama",
    LinkURL: "https://pmg.corp.adobe.com/PanoramaECJ",
    Contextual: "No",
    ContextualURLParameters: "",
    LinkDescription: "",
    PointOfContact: "",
    DocumentationLink: "",
    LinkType: "External",
    ParameterType: "",
  },
];

export default MarketoMenuData;
