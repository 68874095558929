import React from "react";
import { View, Flex, Heading, ProgressCircle } from "@adobe/react-spectrum";
import SentimentNegative from "@spectrum-icons/workflow/SentimentNegative";

export interface ErrorMessageProps {
  title: string;
  isLoading?: boolean;
}

const MessageComponent: React.FC<
  React.PropsWithChildren<ErrorMessageProps>
> = ({ title, isLoading = false, children }) => {
  return (
    <Flex
      margin={"single-line-height"}
      alignItems={"safe center"}
      direction={"column"}
    >
      <View>
        {isLoading ? (
          <ProgressCircle size={"M"} isIndeterminate />
        ) : (
          <SentimentNegative
            margin={"size-0"}
            size={"M"}
            UNSAFE_style={{ opacity: ".25" }}
          />
        )}
      </View>
      <View>
        <Heading level={1} margin={"size-0"} marginBottom={"size-100"}>
          {title}
        </Heading>
      </View>
      <View UNSAFE_style={{ textAlign: "center" }}>{children}</View>
    </Flex>
  );
};

export default MessageComponent;
