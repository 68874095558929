import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import React from "react";
import { useGetEnvironmentListQuery } from "../../../services/dumpTool";
import { ProgressBar, ProgressCircle, View } from "@adobe/react-spectrum";
import FormView from "../../common/FormView";
import DeleteButton from "./DeleteButton";
import getProjectId from "../../../utils/getProjectId";

const DeployInProgress: React.FC = () => {
  const dumpObject = useSelector((state: RootState) => state.dump);
  const caseObject = useSelector((state: RootState) => state.case.caseObject);
  const productMetadata = caseObject?.productMetadata ?? [];
  const projectUrl =
    productMetadata.find((item) => item.displayName === "Project URL")?.value ||
    "";
  const baseUrl = dumpObject.data.apiUrl;

  let branch = "";
  let projectId = "";

  if (projectUrl) {
    projectId = getProjectId(projectUrl);
  }

  if (dumpObject.data.chosenBranch) {
    branch = dumpObject.data.chosenBranch;
  }

  const {
    data: environmentListResponse,
    isLoading: environmentLoading,
    error: environmentLoadingError,
  } = useGetEnvironmentListQuery({
    baseUrl,
    projectId,
    branch,
    ticketId: caseObject?.caseId ?? "",
  });

  if (!environmentListResponse) {
    return null;
  }

  return (
    <FormView
      title={"Deploy Dumps"}
      body={
        <View margin={"size-300"}>
          <ProgressBar
            label="Deploy In Progress…"
            value={environmentListResponse.progress}
          />
          <p>Step: {environmentListResponse.stage}</p>
          {environmentListResponse.deployLogLocation ? (
            <p>
              <a
                href={environmentListResponse.deployLogLocation}
                target={"_blank"}
              >
                Check install log
              </a>
            </p>
          ) : null}
        </View>
      }
      footerInfo={"Deploying dump in progress, please wait..."}
      footerButtonComponent={<DeleteButton />}
    />
  );
};

export default DeployInProgress;
