import React from "react";
import DefectForm from "../components/workfrontAutomation/DefectForm";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";

const P2EAutomationPage: React.FC = () => {
  return <DefectForm />;
};

export default P2EAutomationPage;
