import { useState, useEffect } from "react";
import { formatDate } from "../utils/formatDate";
import { convertToLocalTimeDate } from "../utils/dateTimeUtils";

export const useBannerDateTimes = (
  startDate: string | undefined,
  endDate: string | undefined,
) => {
  const [startDateTime, setStartDateTime] = useState<string | null>(null);
  const [endDateTime, setEndDateTime] = useState<string | null>(null);

  useEffect(() => {
    if (startDate) {
      setStartDateTime(convertToLocalTimeDate(startDate));
    }

    if (endDate) {
      setEndDateTime(convertToLocalTimeDate(endDate));
    }
  }, [startDate, endDate]);

  return { startDateTime, endDateTime };
};
