import { createSlice } from "@reduxjs/toolkit";
import initialState from "./initialState";
import * as stateTypes from "../../types/stateTypes";

const dumpSlice = createSlice({
  name: "dump",
  initialState,
  reducers: {
    setApiUrl: (state, action) => {
      state.data.apiUrl = action.payload;
    },
    mapError: (state, action) => {
      state.currentState = stateTypes.MAP_ERROR_STATE;
      state.data.mapError = true;
      state.data.intermediateState = false;
      state.data.intermediateMessage = action.payload;
    },
    disableDeleteButton: (state) => {
      state.currentState = stateTypes.DELETE_IN_PROGRESS_STATE;
      state.data.isDeleteButtonDisabled = true;
      state.data.mapError = false;
    },
    enableDeleteButton: (state) => {
      state.data.isDeleteButtonDisabled = false;
      state.data.mapError = false;
    },
    environmentError: (state) => {
      state.currentState = stateTypes.ENVIRONMENT_ERROR_STATE;
      state.data.tableSize = initialState.data.tableSize;
      state.data.mapError = false;
      state.data.intermediateState = false;
    },
    hipaaError: (state) => {
      state.currentState = stateTypes.HIPAA_ERROR_STATE;
      state.data.intermediateState = false;
      state.data.intermediateMessage = "";
      state.data.tableSize = initialState.data.tableSize;
      state.data.mapError = false;
    },
    projectIdError: (state) => {
      state.currentState = stateTypes.PROJECT_ID_ERROR_STATE;
      state.data.tableSize = initialState.data.tableSize;
      state.data.mapError = false;
      state.data.intermediateState = false;
    },
    dumpSuccess: (state, action) => {
      state.currentState = stateTypes.DEPLOY_IN_PROGRESS_STATE;
      state.data.tableSize = initialState.data.tableSize;
      state.data.dump = action.payload.dump;
      state.data.mapError = false;
    },
    deleteDumpAction: (state, action) => {
      state.currentState = stateTypes.DELETE_IN_PROGRESS_STATE;
      state.data.intermediateState = true;
      state.data.intermediateMessage = "Deleting dump";
      state.data.isDeleteButtonDisabled = action.payload.disableDeleteButton;
      state.data.mapError = false;
    },
    chooseEnvironment: (state, action) => {
      state.data.environmentList = action.payload.environments;
    },
    chooseBranch: (state, action) => {
      let error = "";
      if (!action.payload) {
        error = "Branch can not be empty";
      }
      state.data.chosenBranch = action.payload;
      state.data.branchError = error;
    },
    dumpAndDeploySuccess: (state, action) => {
      state.currentState = stateTypes.DEPLOY_SUCCESS_STATE;
      state.data.tableSize = initialState.data.tableSize;
      state.data.intermediateState = false;
      state.data.intermediateMessage = "";
      state.data.dump.message = action.payload.message;
      state.data.dump.installation_date = action.payload.installation_date;
      state.data.dump.chosen_branch = action.payload.chosen_branch;
      state.data.dump.code_dump = action.payload.code_dump;
      state.data.dump.db_dump = action.payload.db_dump;
      state.data.dump.dump_dir = action.payload.dump_dir;
      state.data.dump.link = action.payload.link;
      state.data.dump.admin = action.payload.admin;
      state.data.dump.collected_by = action.payload.collected_by;
      state.data.mapError = false;
    },
    dumpInProgress: (state, action) => {
      state.currentState = stateTypes.DUMP_IN_PROGRESS_STATE;
      state.data.tableSize = initialState.data.tableSize;
      state.data.dump = initialState.data.dump;
      state.data.intermediateState = true;
      state.data.intermediateMessage = action.payload;
      state.data.mapError = false;
    },
    dumpInLoadingEnvironmentState: (state) => {
      state.currentState = stateTypes.ENVIRONMENT_LOADING_IN_PROGRESS_STATE;
      state.data.tableSize = initialState.data.tableSize;
      state.data.dump = initialState.data.dump;
      state.data.intermediateState = true;
      state.data.intermediateMessage = "Loading preselected environment...";
      state.data.mapError = false;
    },
    dumpUpdatingBranchInProgress: (state, action) => {
      state.currentState = stateTypes.UPDATING_BRANCH_IN_PROGRESS_STATE;
      state.data.tableSize = initialState.data.tableSize;
      state.data.dump = initialState.data.dump;
      state.data.intermediateState = true;
      state.data.intermediateMessage = action.payload;
      state.data.mapError = false;
    },
    deployInProgress: (state, action) => {
      state.currentState = stateTypes.DEPLOY_IN_PROGRESS_STATE;
      state.data.tableSize = initialState.data.tableSize;
      state.data.dump = initialState.data.dump;
      state.data.progress = action.payload.progress;
      state.data.stage = action.payload.stage;
      state.data.logLocation = action.payload.logLocation;
      state.data.latestDeployLogs = action.payload.latestDeployLogs;
      state.data.intermediateState = false;
      state.data.intermediateMessage = "";
      state.data.mapError = false;
    },
    dumpStuck: (state, action) => {
      state.currentState = stateTypes.DUMP_STUCK_STATE;
      state.data.intermediateState = true;
      state.data.intermediateMessage = action.payload;
    },
    dumpNotFound: (state) => {
      state.currentState = stateTypes.TABLE_SIZE_COLLECTED_STATE;
      state.data.tableSize = initialState.data.tableSize;
      state.data.intermediateState = false;
      state.data.intermediateMessage = "";
      state.data.mapError = false;
    },
    tableSizeInProgress: (state) => {
      state.currentState = stateTypes.TABLE_SIZE_IN_PROGRESS_STATE;
      state.data.tableSize = { ...state.data.tableSize };
    },
    tableSizeNotCollected: (state) => {
      state.currentState = stateTypes.TABLE_SIZE_IS_NOT_COLLECTED_STATE;
      state.data.checkedLinks = [];
      state.data.intermediateState = false;
      state.data.intermediateMessage = "table size is not collected";
      state.data.mapError = false;
    },
    showSkipTablesList: (state, action) => {
      state.currentState = stateTypes.TABLE_SIZE_COLLECTED_STATE;
      state.data.intermediateState = false;
      state.data.intermediateMessage = "";
      state.data.mapError = false;
      state.data.tableSize.items = action.payload.map((item: any) => {
        return {
          table: item.table,
          size: item.size,
          checked: false,
          previousChecked: false,
        };
      });
    },

    setCheckedLinks: (state, action) => {
      state.data = {
        ...state.data,
        intermediateState: false,
        intermediateMessage: "",
        checkedLinks: action.payload,
        mapError: false,
      };
    },
  },
});

export const {
  setApiUrl,
  mapError,
  disableDeleteButton,
  enableDeleteButton,
  environmentError,
  hipaaError,
  projectIdError,
  dumpSuccess,
  deleteDumpAction,
  chooseEnvironment,
  chooseBranch,
  dumpAndDeploySuccess,
  dumpInProgress,
  dumpInLoadingEnvironmentState,
  dumpUpdatingBranchInProgress,
  deployInProgress,
  dumpStuck,
  dumpNotFound,
  tableSizeInProgress,
  tableSizeNotCollected,
  setCheckedLinks,
  showSkipTablesList,
} = dumpSlice.actions;

export default dumpSlice.reducer;
