import React, { useEffect, useState } from "react";
import MessageComponent from "./MessageComponent";
import useCaseData from "../../hooks/useCaseData";
import { useGetIsProductFamilySupportedQuery } from "../../services/supportInsights";
import { ToastQueue } from "@react-spectrum/toast";
import { CaseResponse } from "../../types/case";
import familyNameToCode from "../../utils/familyNameToCode";

interface PageWrapperProps extends React.PropsWithChildren<{}> {
  pageName: string;
}

const defaultErrorMessage =
  "Something went wrong, please verify your case information and refresh the page.";

const PageWrapper: React.FC<PageWrapperProps> = ({ children }) => {
  const {
    caseObject,
    refreshError,
    isLoading,
    caseId,
    caseResponseData,
    forceTicketDataReload,
    doneLoading,
  } = useCaseData();

  const productFamily = caseObject?.productFamilyName ?? "";
  const productFamilyCode = familyNameToCode(productFamily);
  const {
    data,
    error: supportError,
    isLoading: supportLoading,
  } = useGetIsProductFamilySupportedQuery(productFamilyCode, {
    skip: !productFamilyCode,
  });
  const isSupported = data?.data.isSupported;

  const [publicMessage, setPublicMessage] = useState<string | null>(null);

  useEffect(() => {
    if (doneLoading) {
      if (refreshError && "data" in refreshError) {
        const response = refreshError.data as CaseResponse;
        const message = response?.meta?.publicMessage ?? "";
        if (message) {
          setPublicMessage(message);
          ToastQueue.negative(message, { timeout: 5000 });
        }
      }
      if (caseResponseData && "meta" in caseResponseData) {
        const message = caseResponseData.meta.publicMessage;
        if (message) {
          setPublicMessage(message);
          ToastQueue.negative(message, { timeout: 5000 });
        }
      }
    }
  }, [refreshError, caseResponseData, doneLoading]);

  if (isLoading || supportLoading || forceTicketDataReload) {
    return (
      <MessageComponent title={"Oasis is Loading"} isLoading={true}>
        We are fetching your Support Insights for case:{" "}
        <strong>{caseId}</strong>.
      </MessageComponent>
    );
  }

  if (caseObject && isSupported) {
    return <>{children}</>;
  }

  if (!caseObject || refreshError || supportError) {
    return (
      <MessageComponent title="Case unavailable">
        The case <strong>{caseId}</strong> you are searching details for is not
        available.
      </MessageComponent>
    );
  }

  if (!isSupported && caseObject.productFamilyName) {
    return (
      <MessageComponent title="Product not supported">
        The case <strong>{caseId}</strong> belongs to the{" "}
        <strong>{productFamily}</strong> product family. Only cases under Adobe
        DX are supported.
      </MessageComponent>
    );
  }

  return (
    <MessageComponent title="Something went wrong">
      {publicMessage ? publicMessage : defaultErrorMessage}
    </MessageComponent>
  );
};

export default PageWrapper;
