import { Dump } from "../../types/dump";

/**
 * Defines the Initial state.
 */
const initialState: Dump = {
  currentState: "BRANCHES_NOT_COLLECTED",
  data: {
    apiUrl: "",
    isDeleteButtonDisabled: false,
    isAllTablesSelected: false,
    intermediateState: false,
    intermediateMessage: "",
    mapError: true,
    dump_status: "",
    progress: 0,
    stage: "",
    latestDeployLogs: "",
    logLocation: "",
    deployed: false,
    chosenBranch: "",
    branchError: "",
    environmentList: [],
    checkedLinks: [],
    tableSize: {
      items: [],
    },
    dump: {
      installation_date: "",
      collected_by: "",
      chosen_branch: "",
      code_dump: "",
      db_dump: "",
      dump_dir: "",
      link: "",
      admin: "",
      message: "",
    },
  },
};
export default initialState;
