import {
  Content,
  Dialog,
  DialogTrigger,
  Divider,
  Flex,
  Heading,
  Link,
  Text,
  View,
} from "@adobe/react-spectrum";
import React from "react";

interface ContributionComponentProps extends React.PropsWithChildren<{}> {
  title: string;
  developerEmail: string;
  developerSlack?: string;
}

const ContributionComponent: React.FC<ContributionComponentProps> = ({
  children,
  title,
  developerSlack,
  developerEmail,
}) => {
  return (
    <View
      borderRadius="regular"
      backgroundColor="gray-50"
      padding="size-300"
      UNSAFE_className="data-container-wrapper"
    >
      <Flex
        direction="row"
        alignContent="center"
        justifyContent="space-between"
      >
        <p className="aug-analysis-header">{title}</p>
        <Flex gap="size-100" alignContent="center">
          <DialogTrigger type="popover">
            <Link isQuiet>Contact Component Developer</Link>
            <Dialog>
              <Heading>Developer Contact</Heading>
              <Divider />
              <Content>
                <Flex direction="column" gap="size-100">
                  <Text>
                    <span className="developer-contact-info-title">
                      Email:{" "}
                    </span>
                    <span className="developer-contact-info-text">
                      {developerEmail}
                    </span>
                  </Text>
                  {developerSlack && (
                    <Text>
                      <span className="developer-contact-info-title">
                        Slack:{" "}
                      </span>
                      <span className="developer-contact-info-text">
                        {developerSlack}
                      </span>
                    </Text>
                  )}
                </Flex>
              </Content>
            </Dialog>
          </DialogTrigger>
        </Flex>
      </Flex>
      <Divider size="M" marginTop="size-150" />
      {children}
    </View>
  );
};

export default ContributionComponent;
