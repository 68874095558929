import { createSlice } from "@reduxjs/toolkit";
import type { CustomUserClaims, UserClaims } from "@okta/okta-auth-js";

interface UserSlice {
  userDetails: UserClaims<CustomUserClaims> | null;
  error: string | null;
}

const initialState: UserSlice = {
  userDetails: null,
  error: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.userDetails = action.payload;
      state.error = null;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearUser: (state) => {
      state.userDetails = null;
      state.error = null;
    },
  },
});

export const { setUser, setError, clearUser } = userSlice.actions;

export default userSlice.reducer;
