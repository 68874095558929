import {
  Button,
  Content,
  Dialog,
  DialogTrigger,
  Text,
  Flex,
  Heading,
  ActionButton,
  Link,
} from "@adobe/react-spectrum";
import FullScreen from "@spectrum-icons/workflow/FullScreen";
import Close from "@spectrum-icons/workflow/Close";
import logo from "../../assets/svg/adobe_logo.svg";
import React, { useState } from "react";
import Download from "@spectrum-icons/workflow/Download";
import LinkOut from "@spectrum-icons/workflow/LinkOut";
import Bug from "@spectrum-icons/workflow/Bug";
import BugReporter from "../../utils/BugReporter";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { Format } from "../../utils/dataExportUtils";
import { Aggregated } from "../../utils/dataAggregator";
import ChevronRightIcon from "@spectrum-icons/workflow/ChevronRight";
import MarkdownRenderer from "../../utils/renderMarkdown";

export interface ModalProps {
  dialogHeader?: string;
  dialogDescription?: string;
  contentType?: string;
  dialogJSON: Aggregated;
  dialogDownloadFormat: Format;
  dialogShowDownloadBtn: boolean;
  dialogShowSourceBtn: boolean;
  dialogShowSourceLink?: string;
  dialogShowSourceText?: string;
  dialogDownloadFun: (a: Aggregated, b: Format, c: string) => unknown;
  trigger: JSX.Element;
}

const Modal = (props: React.PropsWithChildren<ModalProps>) => {
  const contentType = props.contentType || "Undefined";
  const caseID = useSelector((state: RootState) => state.case.ticketId);
  const dialogHeader = props.dialogHeader || "Data";
  const dialogDescription = props.dialogDescription || "";
  const dialogItemJSON = props.dialogJSON;
  const dialogDownloadFormat = props.dialogDownloadFormat;
  const dialogShowDownloadBtn = props.dialogShowDownloadBtn;
  const dialogDownloadFun = props.dialogDownloadFun;
  const dialogContent = props.children ?? <p>No data provided</p>;
  const [modalType, setModalType] = useState<"modal" | "fullscreen">("modal");
  const onCaptureAndCopy = async () => {
    await dialogDownloadFun(dialogItemJSON, dialogDownloadFormat, dialogHeader);
  };

  function onLinkClick() {
    window.open(props.dialogShowSourceLink, "_blank");
  }

  return (
    <DialogTrigger type={modalType}>
      {props.trigger}
      {(close) => (
        <Dialog UNSAFE_className="oasis-dialog" data-modal-type={modalType}>
          <header className="oasis-dialog__ribbon">
            <div className="oasis-dialog__ribbon_group">
              <img className="modal-adobeLogo" src={logo} alt="" />
              <span className="oasis_dialog__ribbon_title">DX Oasis</span>
            </div>
            <div className="oasis-dialog__ribbon_group">
              <Button
                onPress={() =>
                  setModalType((currentModalType) =>
                    currentModalType === "modal" ? "fullscreen" : "modal",
                  )
                }
                variant="overBackground"
                aria-label="Fullscreen"
                UNSAFE_className={"oasis-dialog__ribbon-button"}
              >
                <FullScreen size={"S"} />
              </Button>

              <Button
                variant="overBackground"
                aria-label="Close"
                UNSAFE_className={"oasis-dialog__ribbon-button"}
                onPress={close}
              >
                <Close size={"S"} />
              </Button>
            </div>
          </header>
          <Content>
            <Heading marginBottom="size-50">
              <Flex
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <span>{dialogHeader}</span>
                <Flex
                  direction="row"
                  justifyContent="space-between"
                  gap="size-100"
                >
                  {props.dialogShowSourceBtn && (
                    <ActionButton aria-label="Source" onPress={onLinkClick}>
                      <LinkOut />
                      <Text>{props.dialogShowSourceText}</Text>
                    </ActionButton>
                  )}
                  <Link
                    href={BugReporter.generateUrl({
                      summary: `Issue with ${dialogHeader} for case ${caseID}`,
                      description: "",
                    }).toString()}
                    target="_blank"
                  >
                    <ActionButton
                      UNSAFE_className="analytics-track-me"
                      data-analytics-link-name="Report a Bug"
                      data-analytics-link-type="exit"
                      data-analytics-view-name={contentType + " Modal"}
                    >
                      <Text>Report a Bug</Text>
                      <Bug size={"S"} />
                    </ActionButton>
                  </Link>
                  {dialogShowDownloadBtn && (
                    <ActionButton
                      aria-label="Download"
                      onPress={onCaptureAndCopy}
                      UNSAFE_className="analytics-track-me"
                      data-analytics-link-name="Download PDF"
                      data-analytics-link-type="download"
                      data-analytics-view-name={contentType + " Modal"}
                    >
                      <Download />
                      <Text>Download</Text>
                    </ActionButton>
                  )}
                </Flex>
              </Flex>
            </Heading>
            {dialogDescription &&
              !dialogHeader?.includes(dialogDescription) && (
                // Auto Collapse of Descriptions over 150 characters.
                <details
                  {...(dialogDescription.length > 250 ? {} : { open: true })}
                >
                  <summary>
                    <Text UNSAFE_className="modal-section-title">
                      Description
                    </Text>
                    <ChevronRightIcon UNSAFE_className="summary-arrow" />
                  </summary>
                  <MarkdownRenderer content={dialogDescription} />
                </details>
              )}
            {dialogContent}
          </Content>
        </Dialog>
      )}
    </DialogTrigger>
  );
};
export default Modal;
