import React from "react";
import { useOktaAuth } from "@okta/okta-react";
import { useDispatch } from "react-redux";
import { setError, setUser } from "../../store/userSlice";
import { addToDataLayer } from "../../utils/analytics";
let handledRedirect = false;
const CustomLoginCallback = () => {
  const { oktaAuth } = useOktaAuth();
  const [, setCallbackError] = React.useState(null);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!handledRedirect) {
      oktaAuth
        .handleLoginRedirect()
        .then((redirectData) => {
          oktaAuth.token
            .getUserInfo()
            .then((userInfo) => {
              dispatch(setUser(userInfo));
              addToDataLayer({ userInfo });

              //  navigate('/'); // Redirect to a dashboard or home page
            })
            .catch((error) => {
              dispatch(setError(error.toString()));
              //navigate('/error');
              // history.push('/error'); // Redirect to an error page
            });
        })
        .catch((e) => {
          setCallbackError(e);
        });
      handledRedirect = true;
    }
  }, [dispatch, oktaAuth]);
  return <></>;
};

export default CustomLoginCallback;
