import React from "react";
import { View, Button, ProgressCircle } from "@adobe/react-spectrum";
import { RootState } from "../../../store/store";
import { useSelector } from "react-redux";
import FormView from "../../common/FormView";
import getEnvironment from "../../../utils/getEnvironment";

interface CreateAdmin {
  createAdmin: any;
  disabledButton: boolean;
  buttonMsg: string;
}

const CreateUser: React.FC<CreateAdmin> = ({
  createAdmin,
  disabledButton,
  buttonMsg,
}) => {
  let caseObject = useSelector((state: RootState) => state.case.caseObject);
  const productMetadata = caseObject?.productMetadata ?? [];
  const user = useSelector(
    (state: RootState) => state.user.userDetails?.email || "",
  );
  let environment =
    productMetadata.find((item) => item.displayName === "Environment")?.value ||
    "";
  // temporary solution for getting valid environment value
  environment = getEnvironment(environment) || "";

  let formattedEnvironment = "";
  if (environment) {
    formattedEnvironment =
      environment.charAt(0).toUpperCase() + environment.slice(1).toLowerCase();
  }
  const tempAdminObject = useSelector((state: RootState) => state.tempAdmin);

  return (
    <FormView
      title={"Admin User Management"}
      body={
        <View>
          <div>
            Environment: <b>{formattedEnvironment}</b>
          </div>
          <div>There is no a generated admin user for {user}</div>
          <div>
            Generate a new user for <i>{formattedEnvironment}</i> environment
          </div>
        </View>
      }
      footerInfo={
        'Click on "Generate an admin user" button to create a new temp admin user'
      }
      footerButtonComponent={
        <Button
          variant="cta"
          aria-label="Generate Admin User"
          isDisabled={disabledButton || !tempAdminObject.isLoaded}
          onPress={() => createAdmin()}
          margin={"size-150"}
          UNSAFE_className="analytics-track-me"
          data-analytics-link-name="Generate Admin User"
        >
          {!tempAdminObject.isLoaded && (
            <ProgressCircle
              aria-label="Loading…"
              isIndeterminate
              size="S"
              marginEnd="size-150"
            />
          )}
          {buttonMsg.length > 0 ? buttonMsg : "Generate an admin user"}
        </Button>
      }
    />
  );
};

export default CreateUser;
