import { IMenuItem } from "../../types/ISideNavbar";

const AdobeExpManagerMenuData: IMenuItem[] = [
  {
    Solution: "Adobe Exp Manager",
    LinkName: "Cloud Acceleration Manager",
    LinkURL: "https://experience.adobe.com/",
    Contextual: "No",
    ContextualURLParameters: "",
    LinkDescription:
      "Cloud Acceleration Manager is a cloud-based application designed to guide your IT teams throughout the transition journey starting from planning to going live on Cloud Service. Set up your teams for a successful migration with Adobe-recommended best practices, tips, documentation, and tools to help at every phase of the journey to AEM as Cloud Service.",
    PointOfContact: "PM : Aditi Dutt Chaudhuri",
    DocumentationLink:
      "https://experienceleague.adobe.com/en/docs/experience-manager-cloud-service/content/migration-journey/cloud-acceleration-manager/introduction-cam/overview-cam",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Adobe Exp Manager",
    LinkName: "Splunk",
    LinkURL: "https://splunk.or1.adobe.net/en-US/app/launcher/home",
    Contextual: "No",
    ContextualURLParameters: "",
    LinkDescription: "",
    PointOfContact: "",
    DocumentationLink: "",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Adobe Exp Manager",
    LinkName: "Cloud Manager Helper",
    LinkURL:
      "https://helpx-internal.corp.adobe.com/content/help/en/experience-manager/kb/tools/cm-helper.html",
    Contextual: "No",
    ContextualURLParameters: "",
    LinkDescription: "",
    PointOfContact: "",
    DocumentationLink: "",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Adobe Exp Manager",
    LinkName: "Node diff",
    LinkURL:
      "https://helpx.adobe.com/experience-manager/kb/tools/aem-node-diff.html",
    Contextual: "No",
    ContextualURLParameters: "",
    LinkDescription:
      "Check nodes across environments using Content Repository Extreme Development Environment (CRX/DE) query service.",
    PointOfContact: "",
    DocumentationLink:
      "https://helpx.adobe.com/experience-manager/kb/tools/aem-node-diff.html",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Adobe Exp Manager",
    LinkName: "Mr FluffyJaws",
    LinkURL:
      "https://aemcs-workspace.adobe.com/bot/dynamics/investigation?caseId={caseId}",
    Contextual: "No",
    ContextualURLParameters: "",
    LinkDescription:
      "ChatGPT bot for assisting TSEs on troubleshooting cases,  automating creation of P2E issues",
    PointOfContact: "",
    DocumentationLink: "https://aemcs-workspace.adobe.com/bot/chat",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Adobe Exp Manager",
    LinkName: "Action Center",
    LinkURL: "https://experience.adobe.com/",
    Contextual: "No",
    ContextualURLParameters: "",
    LinkDescription:
      "Actions Center serves as a hub where you can view and manage AEM incident notifications",
    PointOfContact: "",
    DocumentationLink:
      "https://experienceleague.adobe.com/en/docs/experience-manager-cloud-service/content/operations/actions-center",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Adobe Exp Manager",
    LinkName: "Columbus",
    LinkURL: "https://columbus.corp.adobe.com/",
    Contextual: "No",
    ContextualURLParameters: "",
    LinkDescription: "",
    PointOfContact: "",
    DocumentationLink: "",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Adobe Exp Manager",
    LinkName: "ABO / Login-As",
    LinkURL:
      "https://abo.services.adobe.com/customers/search?query={requestor_email}",
    Contextual: "No",
    ContextualURLParameters: "",
    LinkDescription: "",
    PointOfContact: "",
    DocumentationLink: "",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Adobe Exp Manager",
    LinkName: "Panorama",
    LinkURL: "https://pmg.corp.adobe.com/PanoramaECJ",
    Contextual: "No",
    ContextualURLParameters: "",
    LinkDescription: "",
    PointOfContact: "",
    DocumentationLink: "",
    LinkType: "External",
    ParameterType: "",
  },
];

export default AdobeExpManagerMenuData;
