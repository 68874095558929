import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import userReducer from "./userSlice";
import userRoleReducer from "./userRoleSlice";
import caseReducer from "./case/caseSlice";
import dumpReducer from "./dump/dumpSlice";
import tempAdminReducer from "./tempAdminUser/tempAdminSlice";
import lastAnalysisReducer from "./lastAnalysisSlice";
import activeTabReducer from "./activeTabSlice";
import findingsSliceReducer from "./findings/findingsSlice";
import { supportInsightsApi } from "../services/supportInsights";
import { healthCheckApi } from "../services/healthCheck";
import { dumpToolApi } from "../services/dumpTool";
import { tempAdminApi } from "../services/tempAdmin";
import bannerReducer from "./bannerSlice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    userRole: userRoleReducer,
    banner: bannerReducer,
    case: caseReducer,
    dump: dumpReducer,
    tempAdmin: tempAdminReducer,
    lastAnalysis: lastAnalysisReducer,
    findings: findingsSliceReducer,
    activeTab: activeTabReducer,
    [supportInsightsApi.reducerPath]: supportInsightsApi.reducer,
    [healthCheckApi.reducerPath]: healthCheckApi.reducer,
    [dumpToolApi.reducerPath]: dumpToolApi.reducer,
    [tempAdminApi.reducerPath]: tempAdminApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      supportInsightsApi.middleware,
      healthCheckApi.middleware,
      dumpToolApi.middleware,
      tempAdminApi.middleware,
    ),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
