import React from "react";
import { Link, View } from "@adobe/react-spectrum";

const HomePage = () => {
  document.title = "Home";
  return (
    <View margin={"single-line-height"}>
      <h1>Hello world!</h1>
      <p>
        Welcome to Oasis.{" "}
        <Link
          href={
            "https://wiki.corp.adobe.com/display/CESTRATINTELandOPS/Get+Started"
          }
        >
          Learn about CCF
        </Link>{" "}
        {/*&bull; <Link href={"/search"}>Go to Search Case</Link>*/}
        &bull; <Link href={"/review/case/E-001568392/"}>Go to Test Case</Link>
      </p>
    </View>
  );
};

export default HomePage;
