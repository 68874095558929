interface Satellite {
  track: (event: string, data?: Record<string, unknown>) => void;
}

function getSatellite(): Satellite | undefined {
  // @ts-ignore -- Satellite is defined by the Adobe Analytics script
  return window._satellite;
}

export function track(...args: Parameters<Satellite["track"]>) {
  const satellite = getSatellite();
  if (satellite) {
    satellite.track(...args);
  }
}

export function addToDataLayer<T = unknown>(data: T) {
  // @ts-expect-error -- dataLayer is defined by the Adobe Analytics script
  const original = window.dataLayer ?? {}
  // @ts-expect-error -- dataLayer is defined by the Adobe Analytics script
  window.dataLayer = {
    ...original,
    ...data,
  }
}
