import { IMenuItem } from "../../types/ISideNavbar";
import AugmentedAnalysisPage from "../../pages/AugmentedAnalysisPage";
import AiAssistantPage from "../../pages/AiAssistant";

// Do not use "All Solutions" unless the page you are building is of use for all Dx Products, for everything else use the appropriate product.
// See <https://wiki.corp.adobe.com/x/NLK_uQ>
const AllSolutionsMenuData: IMenuItem[] = [
  {
    Solution: "All Solutions",
    LinkName: "AI Assistant",
    LinkURL: `/review/:caseInfoSuffix`,
    Contextual: "No",
    ContextualURLParameters: "",
    LinkDescription: "",
    PointOfContact: "",
    DocumentationLink: "",
    LinkType: "Internal",
    ParameterType: "",
    component: <AiAssistantPage />,
  },
  {
    Solution: "All Solutions",
    LinkName: "Augmented Analysis",
    LinkURL: `/augmented-analysis/:caseInfoSuffix`,
    Contextual: "No",
    ContextualURLParameters: "",
    LinkDescription: "",
    PointOfContact: "",
    DocumentationLink: "",
    LinkType: "Internal",
    ParameterType: "",
    component: <AugmentedAnalysisPage />,
  },
  {
    Solution: "All Solutions",
    LinkName: "C14E Text Resize Chrome Extensions",
    LinkURL:
      "https://wiki.corp.adobe.com/display/MKTOCS/C14E+Text+Resize+Chrome+Extension",
    Contextual: "No",
    ContextualURLParameters: "",
    LinkDescription: "",
    PointOfContact: "",
    DocumentationLink: "",
    LinkType: "External",
    ParameterType: "",
  },
];

export default AllSolutionsMenuData;
