import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  lastAnalysisDate: "",
};

const lastAnalysisSlice = createSlice({
  name: "lastAnalysis",
  initialState,
  reducers: {
    setLastAnalysisDate: (state, action) => {
      state.lastAnalysisDate = action.payload;
    },
  },
});

export const { setLastAnalysisDate } = lastAnalysisSlice.actions;
export default lastAnalysisSlice.reducer;
