import React from "react";
import DataContainer from "../components/aiAssistant/DataContainer";
import PageWrapper from "../components/common/PageWrapper";
import TicketInfoBanner from "../components/augmentedAnalysis/TicketInfoBanner";
import { Flex } from "@adobe/react-spectrum";

const AiAssistantPage: React.FC = () => {
  document.title = "AI Assistant";
  return (
    <PageWrapper pageName="AI Assistant">
      <Flex direction="column" gap="size-400">
        <TicketInfoBanner />
        <DataContainer />
      </Flex>
    </PageWrapper>
  );
};

export default AiAssistantPage;
