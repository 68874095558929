import { Button, View } from "@adobe/react-spectrum";
import React from "react";
import "../styles/Pages/NotFoundPage.scss";
import AppIcon from "@spectrum-icons/workflow/App";
interface FeatureCardProps {
  title: string;
  description: string;
}

const FeatureCard: React.FC<FeatureCardProps> = ({ title, description }) => (
  <div className="FeatureCard">
    <div>
      <AppIcon></AppIcon>
    </div>
    <div className="FeatureCard_title">{title}</div>
    <div className="FeatureCard_description">{description}</div>
    <div className="FeatureCard_button">
      <Button variant="accent" UNSAFE_className="FeatureCard_button_text">
        Learn More
      </Button>
    </div>
  </div>
);
const NotFoundPage = () => {
  document.title = "Not Found";
  return (
    <View UNSAFE_className="NotFound_wrapperr">
      <div style={{ padding: "2rem" }}>
        <h1>Sorry, we couldn't find that page.</h1>
        <p>
          Head over to the <a href="/">Dynamics homepage</a>, or check out some
          of our other dashboards below.
        </p>
        <div className="FeatureCard_wrapper">
          <FeatureCard
            title="Digital Support Insights"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
          />
          <FeatureCard
            title="Jira"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
          />
          <FeatureCard
            title="Gen AI"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
          />
          <FeatureCard
            title="AI Assistance"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
          />
          <FeatureCard
            title="Panorama"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
          />
        </div>
      </div>
    </View>
  );
};

export default NotFoundPage;
