import React from "react";
import { View, Text, Link, Flex } from "@adobe/react-spectrum";
import ArrowRight from "@spectrum-icons/workflow/ArrowRight";
import Bug from "@spectrum-icons/workflow/Bug";
import BugReporter from "../../utils/BugReporter";
import SearchBar from "../search/SearchBar";

const FeedbackBanner: React.FC = () => {
  return (
    <View colorVersion={6} backgroundColor="indigo-900" paddingX="size-500">
      <div className="Feedback-Banner">
        <View>
          <SearchBar />
        </View>
        <Flex gap={40} marginEnd={10}>
          {/*<View>*/}
          {/*  <Link*/}
          {/*    href="https://forms.office.com/r/3vy08qqE6p"*/}
          {/*    UNSAFE_className="analytics__Feedback"*/}
          {/*    target="_blank"*/}
          {/*  >*/}
          {/*    <Text>Submit Feedback</Text>*/}
          {/*    <ArrowRight*/}
          {/*      size="S"*/}
          {/*      position="relative"*/}
          {/*      top="size-50"*/}
          {/*      left="size-100"*/}
          {/*    />*/}
          {/*  </Link>*/}
          {/*</View>*/}

          <View>
            <Link
              href="https://adobe.sharepoint.com/sites/DXSupportInsights/SitePages/CCF.aspx?csf=1&web=1&e=DcmkbP"
              target="_blank"
              UNSAFE_className="analytics-track-me"
              data-analytics-link-name="Feature Request"
              data-analytics-link-type="exit"
              data-analytics-view-name="Header"
            >
              <Text>Feature Request</Text>
              <ArrowRight
                size="S"
                position="relative"
                top="size-50"
                left="size-100"
              />
            </Link>
          </View>

          <View>
            <Link
              href={BugReporter.formURL}
              target="_blank"
              UNSAFE_className="analytics-track-me"
              data-analytics-link-name="Report a Bug"
              data-analytics-link-type="exit"
              data-analytics-view-name="Header"
            >
              <Text>Report a Bug</Text>
              <Bug size="S" position="relative" top="size-50" left="size-100" />
            </Link>
          </View>
        </Flex>
      </div>
    </View>
  );
};
export default FeedbackBanner;
