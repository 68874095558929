export const DUMP_STUCK_STATE = "DUMP_STUCK_STATE";
export const DUMP_IN_PROGRESS_STATE = "DUMP_IN_PROGRESS_STATE";
export const TABLE_SIZE_IS_NOT_COLLECTED_STATE =
  "TABLE_SIZE_IS_NOT_COLLECTED_STATE";
export const ENVIRONMENT_LOADING_IN_PROGRESS_STATE =
  "ENVIRONMENT_LOADING_IN_PROGRESS_STATE";
export const UPDATING_BRANCH_IN_PROGRESS_STATE =
  "UPDATING_BRANCH_IN_PROGRESS_STATE";
export const TABLE_SIZE_COLLECTED_STATE = "TABLE_SIZE_COLLECTED_STATE";
export const TABLE_SIZE_IN_PROGRESS_STATE = "TABLE_SIZE_IN_PROGRESS_STATE";
export const BRANCH_NOT_CHOSEN_STATE = "BRANCH_NOT_CHOSEN_STATE";
export const BRANCH_CHOSEN_STATE = "BRANCH_CHOSEN_STATE";
export const BRANCHES_NOT_COLLECTED = "BRANCHES_NOT_COLLECTED";
export const DEPLOY_IN_PROGRESS_STATE = "DEPLOY_IN_PROGRESS_STATE";
export const DEPLOY_STUCK_STATE = "DEPLOY_STUCK_STATE";
export const DEPLOY_SUCCESS_STATE = "DEPLOY_SUCCESS_STATE";
export const DELETE_IN_PROGRESS_STATE = "DELETE_IN_PROGRESS_STATE";
export const MAP_ERROR_STATE = "MAP_ERROR_STATE";
export const ENVIRONMENT_ERROR_STATE = "ENVIRONMENT_ERROR_STATE";
export const DATA_CONSENT_ERROR_STATE = "DATA_CONSENT_ERROR_STATE";
export const HIPAA_ERROR_STATE = "HIPAA_ERROR_STATE";
export const PROJECT_ID_ERROR_STATE = "PROJECT_ID_ERROR_STATE";
