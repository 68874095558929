import React from "react";
import { Flex } from "@adobe/react-spectrum";
import ContributionComponent from "../common/ContributionComponent";
import TempAdmin from "./tempAdmin/TempAdmin";
import TicketInfoBanner from "../augmentedAnalysis/TicketInfoBanner";

const CreateAdmin: React.FC = () => {
  return (
    <Flex direction="column" gap="size-400">
      <TicketInfoBanner />
      <ContributionComponent
        title={"Create Temporary Admin"}
        developerEmail={"poltorat@adobe.com, atw24003@adobe.com"}
      >
        <Flex direction="row" gap="size-500">
          <TempAdmin />
        </Flex>
      </ContributionComponent>
    </Flex>
  );
};

export default CreateAdmin;
