import React from "react";
import UserBanner from "./UserBanner";
import AdminBanner from "./AdminBanner";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

const Banner: React.FC = () => {
  const userRole = useSelector((state: RootState) => state.userRole.userRole);
  if (!userRole) {
    return null;
  }
  return userRole === "admin" ? <AdminBanner /> : <UserBanner />;
};
export default Banner;
