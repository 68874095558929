// src/layouts/TabContainer.tsx
import React from "react";
import Navbar from "../components/common/Navbar"; // Adjust the import path as needed
import { Grid, View } from "@adobe/react-spectrum";
import FeedbackBanner from "../components/common/FeedbackBanner";
import SideNavbar from "../components/common/SideNavbar";
import "../styles/main.scss";
import "../styles/BaseLayout.scss";
import FooterLogo from "../assets/svg/Footer_logo.svg";
import { Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { useGetHealthCheckQuery } from "../services/healthCheck";
import MessageComponent from "../components/common/MessageComponent";
import createSidebarConfig from "../utils/createSidebarConfig";
import FeedbackForm from "../components/common/FeedbackForm";

interface BaseLayoutGrid {
  areas: string[];
  columns: string[];
}

const BaseLayout: React.FC = () => {
  const { isLoading, error } = useGetHealthCheckQuery();
  const caseObject = useSelector((state: RootState) => state.case.caseObject);
  const sidebarConfig = createSidebarConfig(caseObject);
  const { pathname } = useLocation();
  const baseLayoutGrid = getBaselayout(pathname);

  const Content_maxHeight_style = {
    maxHeight: "calc(100vh - 81px)",
  };
  const sidebar_Height_style = {
    Height: "calc(100vh - 81px)",
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <MessageComponent title={"Oasis is Loading"} isLoading={true}>
          We are fetching your Support Insights. If this is taking too long,
          verify that you are connected to the Adobe VPN.
        </MessageComponent>
      );
    }

    if (error) {
      return (
        <MessageComponent title={"Unable To Connect To Server"}>
          There was a networking issue. Verify that you are connected to the
          Adobe VPN.
        </MessageComponent>
      );
    }

    return (
      <View padding="size-400">
        <Outlet />
      </View>
    );
  };

  return (
    <Grid
      areas={[...baseLayoutGrid.areas]}
      columns={[...baseLayoutGrid.columns]}
      rows={["max-content", "42px", "auto", "30px"]}
      height="100vh"
      gap="size-0"
    >
      <View
        gridArea="header"
        backgroundColor="gray-200"
        UNSAFE_style={{ position: "sticky", top: 0, zIndex: 2 }}
      >
        <Navbar />
      </View>
      {pathname !== "/" && (
        <View
          gridArea="sidebar"
          backgroundColor="gray-50"
          borderEndColor="gray-200"
          borderEndWidth="thin"
          UNSAFE_style={{
            position: "sticky",
            top: "max-content",
            ...sidebar_Height_style,
          }} // Subtract the total height of the header, feedback, and footer
        >
          <SideNavbar config={sidebarConfig.config} />
        </View>
      )}
      <View
        gridArea="feedback"
        backgroundColor="gray-200"
        UNSAFE_style={{ position: "sticky", top: "max-content", zIndex: 1 }}
      >
        <FeedbackBanner />
        <FeedbackForm />
      </View>
      <View
        gridArea="content"
        overflow="auto"
        UNSAFE_style={Content_maxHeight_style} // Subtract the total height of the header and footer
      >
        {renderContent()}
      </View>
      <View gridArea="footer" UNSAFE_className="footer">
        <View UNSAFE_className="footer_left_section">
          <img className="Footer-adobeLogo" src={FooterLogo} alt=""></img>
          <View UNSAFE_className="footer_left_Copyright">
            &copy; Copyright © 2024 Adobe. All rights reserved.
          </View>
        </View>
        <View UNSAFE_className="footer_right_section">
          <a
            className="footer_right_section_link"
            href="https://wiki.corp.adobe.com/display/CESTRATINTELandOPS/Privacy+Policy"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          <a
            className="footer_right_section_link"
            href="https://wiki.corp.adobe.com/display/CESTRATINTELandOPS/Terms+of+Service"
            target="_blank"
            rel="noreferrer"
          >
            Terms of Service
          </a>
        </View>
      </View>
    </Grid>
  );
};

export default BaseLayout;

const getBaselayout = (pathname: string): BaseLayoutGrid => {
  if (pathname === "/") {
    return {
      areas: [
        "header  header",
        "feedback feedback",
        "content content",
        "footer footer",
      ],
      columns: ["1fr"],
    };
  }
  return {
    areas: [
      "header  header",
      "sidebar feedback",
      "sidebar content",
      "footer footer",
    ],
    columns: ["1fr", "5fr"],
  };
};
