import React from "react";
import { Flex } from "@adobe/react-spectrum";
import CreateUser from "./CreateUser";
import UserInfo from "./UserInfo";
import useTempAdmin from "../../../hooks/useTempAdmin";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import Hipaa from "./Hipaa";

const TempAdmin: React.FC = () => {
  const { handleDeleteAdmin, handleCreateAdmin, tempAdminObject } =
    useTempAdmin();
  const caseObject = useSelector((state: RootState) => state.case.caseObject);
  const ticketStatus = caseObject?.statusReasonText?.toLowerCase() || "";
  //TODO: get actual hipaa value from dynamic data
  const hipaa = false;

  const disabledButton = [
    "resolution provided",
    "pending response",
    "resolution confirmed",
    "issue obsolete",
    "auto closed",
    "cancel/misroute",
    "merged",
  ].includes(ticketStatus);
  const buttonMsg = disabledButton
    ? `Disabled in the status ${caseObject?.statusReasonText}`
    : "";

  return (
    <Flex>
      {hipaa ? (
        <Hipaa
          createAdmin={handleCreateAdmin}
          disabledButton={disabledButton}
          buttonMsg={buttonMsg}
          hipaa={hipaa}
        />
      ) : !tempAdminObject.user.email ? (
        <CreateUser
          createAdmin={handleCreateAdmin}
          disabledButton={disabledButton}
          buttonMsg={buttonMsg}
        />
      ) : tempAdminObject.user.email ? (
        <UserInfo
          deleteAdmin={handleDeleteAdmin}
          tempAdminObject={tempAdminObject}
          buttonMsg={buttonMsg}
        />
      ) : null}
    </Flex>
  );
};

export default TempAdmin;
