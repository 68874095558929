import React, { useState, useEffect } from "react";
import { Button, Text, ProgressCircle } from "@adobe/react-spectrum";
import Refresh from "@spectrum-icons/workflow/Refresh";
import {
  useDynamicsCollectDataMutation,
  useRefreshTicketMutation,
} from "../../services/supportInsights";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store/store";
import familyNameToCode from "../../utils/familyNameToCode";
import { handleRefresh } from "../../utils/handleRefresh";
import { collectDynamicsData } from "../../utils/collectDynamicsData";
import { CaseObject } from "../../types/case";

function RefreshDataButton() {
  const dispatch = useDispatch();
  const [refreshTicketData, { isLoading: ticketIsLoading }] =
    useRefreshTicketMutation();
  const [dynamicsCollectData, { isLoading: dynamicsIsLoading }] =
    useDynamicsCollectDataMutation();
  const [isDisabled, setIsDisabled] = useState(false);

  const caseObject = useSelector((state: RootState) => state.case.caseObject);
  const caseId = caseObject?.caseId ?? "";
  const orgId = caseObject?.orgId ?? "";
  const rengaId = caseObject?.admin?.rengaId ?? "";
  const productFamily = caseObject?.productFamilyName ?? "";
  const productFamilyCode = familyNameToCode(productFamily);

  const handleRefreshClick = () => {
    handleRefresh({
      caseId,
      orgId,
      rengaId,
      productFamily,
      refreshTicketData,
      setIsDisabled,
      dispatch,
    }).then(() => {
      if (caseObject !== undefined && !dynamicsIsLoading) {
        collectDynamicsData({
          caseObject,
          dynamicsCollectData,
          productFamilyCode,
          dispatch,
          setIsDisabled,
        });
      }
    });
  };

  return (
    <Button
      variant="cta"
      aria-label="Refresh data"
      isDisabled={ticketIsLoading || dynamicsIsLoading || isDisabled}
      onPress={handleRefreshClick}
    >
      {ticketIsLoading || dynamicsIsLoading ? (
        <ProgressCircle
          aria-label="Loading…"
          isIndeterminate
          size="S"
          marginEnd="size-150"
        />
      ) : (
        <Refresh />
      )}
      <Text>Refresh All</Text>
    </Button>
  );
}

export default RefreshDataButton;
