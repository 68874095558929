import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sortBy: { column: "created_at", orderby: "descending" },
  filterColumn: {},
};

const findingsSlice = createSlice({
  name: "findings",
  initialState,
  reducers: {
    setSortBy: (state, action) => {
      state.sortBy = action.payload;
    },
    setFilterColumn: (state, action) => {
      state.filterColumn = action.payload;
    },
  },
});

export const { setSortBy, setFilterColumn } = findingsSlice.actions;
export default findingsSlice.reducer;
