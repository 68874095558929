import { createSlice } from "@reduxjs/toolkit";
import { Case } from "../../types/case";

const initialState: Case = {
  ticketId: "",
  orgId: "",
  orgName: "",
  rengaId: "",
  searchText: "",
  aiSummary: {
    isLoading: false,
    prompt: "",
    text: "",
  },
  aiSuggestions: {
    isLoading: false,
  },
  aiArticle: {
    text: "",
    isLoading: false,
  },
  kbArticles: [],
  kbArticlesFetched: false,
  caseList: null,
  findings: [],
  isTicketRefreshing: false,
  isDataCollecting: false,
};

const caseSlice = createSlice({
  name: "case",
  initialState,
  reducers: {
    setCase: (state, action) => {
      state.caseObject = action.payload;
    },
    setTicketId: (state, action) => {
      state.ticketId = action.payload;
    },
    setOrgId: (state, action) => {
      state.orgId = action.payload;
    },
    setOrgName: (state, action) => {
      state.orgName = action.payload;
    },
    setRengaId: (state, action) => {
      state.rengaId = action.payload;
    },
    setAiSummary: (state, action) => {
      state.aiSummary = { ...state.aiSummary, ...action.payload };
    },
    setAiSuggestions: (state, action) => {
      state.aiSuggestions = { ...state.aiSuggestions, ...action.payload };
    },
    setAiArticle: (state, action) => {
      state.aiArticle = { ...state.aiArticle, ...action.payload };
    },
    setKbArticles: (state, action) => {
      state.kbArticles = action.payload;
    },
    setKbArticlesFetched: (state, action) => {
      state.kbArticlesFetched = action.payload;
    },
    setSearchText: (state, action) => {
      state.searchText = action.payload;
    },
    setCaseList: (state, action) => {
      state.caseList = { ...action.payload };
    },
    setFindings: (state, action) => {
      state.findings = [...action.payload];
    },
    setIsTicketRefreshing: (state, action) => {
      state.isTicketRefreshing = action.payload;
    },
    setIsDataCollecting: (state, action) => {
      state.isDataCollecting = action.payload;
    },
  },
});

export const {
  setCase,
  setTicketId,
  setOrgId,
  setOrgName,
  setRengaId,
  setAiSummary,
  setAiSuggestions,
  setAiArticle,
  setKbArticles,
  setKbArticlesFetched,
  setSearchText,
  setCaseList,
  setFindings,
  setIsTicketRefreshing,
  setIsDataCollecting,
} = caseSlice.actions;
export default caseSlice.reducer;
