import React from "react";
import { View, Flex } from "@adobe/react-spectrum";
import PageWrapper from "../components/common/PageWrapper";
import ContributionComponent from "../components/common/ContributionComponent";
import DefectDashboard from "../components/workfrontAutomation/DefectDashboard";

const WorkfrontAutomationPage: React.FC = () => {
  return (
    //The PageWrapper Component handles the Case Data fetching and error handling
    //It also tracks the page analytics
    //The PageWrapper Component is a custom component that is not part of the React Spectrum library
    //It also handles the initial loading states
    <Flex direction="column" gap="size-400">
      <ContributionComponent
        title="Workfront Automation"
        developerEmail="grimaud@adobe.com, nfinsand@adobe.com"
        developerSlack="#oasis-slack"
      >
        <View
          marginTop={"size-250"}
          marginStart={"size-500"}
          marginEnd={"size-500"}
        >
          <DefectDashboard />
        </View>
      </ContributionComponent>
    </Flex>
  );
};

export default WorkfrontAutomationPage;
