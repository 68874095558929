import React from "react";
import { View, Flex } from "@adobe/react-spectrum";
import TabContainer from "./TabContainer";
import logo from "../../assets/svg/aug_analysis_logo.svg";

const DataContainer: React.FC = () => {
  return (
    <div className="data-container-wrapper">
      <View borderRadius="regular" backgroundColor="gray-50">
        <Flex direction="column" gap="size-50">
          <View padding="size-250">
            <Flex gap="size-150" direction="row" alignContent="center">
              <img className="aug-analysis-logo" src={logo} alt=""></img>
              <p className="aug-analysis-header">Augmented Analysis</p>
            </Flex>
          </View>
          <View paddingX="size-1000" paddingY="size-250" minHeight={400}>
            <TabContainer />
          </View>
        </Flex>
      </View>
    </div>
  );
};

export default DataContainer;
