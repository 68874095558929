import React, { useEffect } from "react";
import {
  ActionButton,
  Button,
  Flex,
  Heading,
  ProgressCircle,
  View,
} from "@adobe/react-spectrum";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import Refresh from "@spectrum-icons/workflow/Refresh";
import {
  setAiArticle,
  setKbArticlesFetched,
} from "../../../store/case/caseSlice";
import {
  createJiraTicketRequest,
  CreateKbArticleRequest,
  createKbArticleRequest,
  fetchAIResponseStream,
} from "../../../services/requestServices";
import Copy from "@spectrum-icons/workflow/Copy";
import Preview from "@spectrum-icons/workflow/Preview";
import "./AiKnowledgeCenter.css";
import { ToastQueue } from "@react-spectrum/toast";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import "@mdxeditor/editor/style.css";
import {
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  codeBlockPlugin,
  codeMirrorPlugin,
  CodeToggle,
  diffSourcePlugin,
  DiffSourceToggleWrapper,
  headingsPlugin,
  InsertCodeBlock,
  InsertTable,
  linkPlugin,
  listsPlugin,
  ListsToggle,
  markdownShortcutPlugin,
  MDXEditor,
  MDXEditorMethods,
  quotePlugin,
  sandpackPlugin,
  StrikeThroughSupSubToggles,
  tablePlugin,
  toolbarPlugin,
  UndoRedo,
} from "@mdxeditor/editor";
import { get } from "lodash";
import { useAiArticle } from "../../../hooks/useAiArticle";
import TableDataView from "../AiKnowledgeCenter/TableDataView";
import { useKbArticles } from "../../../hooks/useKbArticles";

const AiKnowledgeCenter: React.FC = () => {
  const dispatch = useDispatch();
  const [aiArticleText, aiArticleIsLoading, getAiArticle, reload] =
    useAiArticle();
  let [isReview, setIsReview] = React.useState(false);
  let [isViewList, setIsViewList] = React.useState(true);
  let [isReviewed, setIsReviewed] = React.useState(false);
  let [isPublic, setIsPublic] = React.useState(false);
  let [jiraTicketIsPending, setJiraTicketIsPending] = React.useState(false);
  const ref = React.useRef<MDXEditorMethods>(null);
  const caseObject = useSelector((state: RootState) => state.case.caseObject);
  const caseId = caseObject?.caseId || "";
  const [isKbArticlesRefreshing, getKbArticles, reloadKbArticles] =
    useKbArticles(caseId);
  const productFamily = caseObject?.productFamilyName || "";
  const user = useSelector((state: RootState) => state.user.userDetails);
  const kbArticles =
    useSelector((state: RootState) => state.case.kbArticles) || [];
  const kbArticlesFetched =
    useSelector((state: RootState) => state.case.kbArticlesFetched) || false;
  useEffect(() => {
    if (!kbArticlesFetched) {
      getKbArticles();
    }
  }, [kbArticlesFetched]);
  const createKbArticle = (body: string) => {
    setJiraTicketIsPending(true);
    let labels = ["oasis"];
    labels.push(isPublic ? "public" : "internal");
    let userName = "";
    let userNameMatchArr = user?.email?.match(/([a-zA-Z0-9._%+-]+)@/);
    if (userNameMatchArr) {
      userName = userNameMatchArr[1];
    } else {
      if (user?.userid) {
        userName = user?.userid.toString();
      }
    }

    const jiraRequest = {
      projectId: "DXSKB",
      summary: "Adobe Support Insights: " + caseId + " - Article Request",
      description: "{code}" + body + "{code}",
      issueType: "Documentation",
      labels: labels,
      userName: userName || "",
    };
    createJiraTicketRequest(jiraRequest)
      .then((response) => {
        const jiraTicketKey = get(response, "data.data.key", "000000");
        ToastQueue.positive(
          "Knowledge article submitted and Jira ticket " +
            jiraTicketKey +
            " has been created successfully.",
          {
            timeout: 3000,
          },
        );
        setJiraTicketIsPending(false);
        setIsReview(false);
        const kbArticleRequest: CreateKbArticleRequest = {
          jira_id: jiraTicketKey,
          dynamics_id: caseId,
          summary: jiraRequest["summary"],
          is_public: isPublic,
          created_by: jiraRequest["userName"],
          reviewed_by: jiraRequest["userName"],
          solution: productFamily,
          status: "in-review",
          content: body,
        };
        setJiraTicketIsPending(true);
        createKbArticleRequest(kbArticleRequest)
          .then((response) => {
            const internalId = get(response, "data.id", "000000");
            dispatch(setKbArticlesFetched(false));
            ToastQueue.positive(
              "Knowledge article submitted and internal id " + internalId,
              {
                timeout: 3000,
              },
            );
            setJiraTicketIsPending(false);
            setIsReview(false);
          })
          .catch((error: any) => {
            ToastQueue.negative(
              "Sorry for the inconvenience, we are facing Data Retrieval Error. Please visit after sometime.",
              { timeout: 5000 },
            );
          });
      })
      .catch((error: any) => {
        ToastQueue.negative(
          "Sorry for the inconvenience, we are facing Data Retrieval Error. Please visit after sometime.",
          { timeout: 5000 },
        );
      });
  };

  // @fixme: work around for MDXEditor syntax error for ``` and ---
  const fixMdFormatting = (text: string) => {
    const codeBlockRegex = /```[a-zA-Z]*/g;
    return text.replace(codeBlockRegex, "`").replace(/---/g, "");
  };

  if (!aiArticleText)
    return (
      <View>
        <Flex direction="column">
          <Flex direction="row" justifyContent="center">
            <View paddingY="size-1200" UNSAFE_style={{ textAlign: "center" }}>
              <Heading level={2}>
                Let&rsquo;s get started generating Knowledge Article!
              </Heading>
              <Button
                variant="cta"
                onPress={(e) => {
                  getAiArticle();
                }}
              >
                Generate a New Article from this Case Details
              </Button>
            </View>
          </Flex>
        </Flex>
      </View>
    );

  if (isViewList && kbArticles && kbArticles.length > 0) {
    let kbArticlesCollection = [];
    let contentMap: { [key: string]: string } = {};
    for (let i = 0; i < kbArticles.length; i++) {
      kbArticlesCollection.push({
        "JIRA ID": kbArticles[i].jira_id,
        DESCRIPTION: kbArticles[i].summary,
        "ARTICLE USE FOR": kbArticles[i].is_public ? "Public" : "Internal",
        STATUS: kbArticles[i].status,
      });
      contentMap[kbArticles[i].jira_id] = kbArticles[i].content;
    }
    return TableDataView(
      kbArticlesCollection,
      setIsViewList,
      setIsReview,
      contentMap,
      getAiArticle,
    );
  }

  if (!isViewList && isReview)
    return (
      <Flex direction="column">
        <Flex direction="row" gap="size-200" wrap="wrap">
          <MDXEditor
            ref={ref}
            markdown={fixMdFormatting(aiArticleText || "")}
            plugins={[
              headingsPlugin(),
              diffSourcePlugin({
                diffMarkdown: aiArticleText || "",
                viewMode: "rich-text",
              }),
              codeBlockPlugin({ defaultCodeBlockLanguage: "sql" }),
              listsPlugin(),
              quotePlugin(),
              tablePlugin(),
              codeMirrorPlugin(),
              linkPlugin(),
              quotePlugin(),
              markdownShortcutPlugin(),
              sandpackPlugin(),
              toolbarPlugin({
                toolbarContents: () => (
                  <DiffSourceToggleWrapper>
                    {" "}
                    <BlockTypeSelect />
                    <BoldItalicUnderlineToggles />
                    <CodeToggle />
                    <StrikeThroughSupSubToggles />
                    <ListsToggle />
                    <InsertTable />
                    <UndoRedo />
                    {/*<InsertCodeBlock />*/}
                  </DiffSourceToggleWrapper>
                ),
              }),
            ]}
          />
          <Flex direction="column">
            <Flex direction="row" marginBottom={"size-150"}>
              <input
                type="checkbox"
                id="scales"
                name="scales"
                checked={isReviewed}
                onChange={(e) => setIsReviewed(e.target.checked)}
              />
              <label htmlFor="scales">
                I have personally reviewed and verified the modified content.
              </label>
            </Flex>
            <Flex direction="row" marginBottom={"size-150"}>
              <input
                type="checkbox"
                id="publicly_available"
                name="publicly_available"
                checked={isPublic}
                onChange={(e) => setIsPublic(e.target.checked)}
              />
              <label htmlFor="publicly_available">
                Make this article external (public).
              </label>
            </Flex>
          </Flex>
        </Flex>
        <Flex direction="row" gap="size-200" wrap="wrap">
          <Flex>
            <Button
              variant="cta"
              isDisabled={jiraTicketIsPending}
              onPress={() => {
                const knowledgeArticle = ref.current?.getMarkdown() || "";
                localStorage.setItem(
                  "knowledgeArticle" + caseId,
                  knowledgeArticle,
                );
                dispatch(setAiArticle({ text: knowledgeArticle }));
                setIsReview(false);
              }}
            >
              Save
            </Button>
          </Flex>
          <Flex>
            <Button
              variant="cta"
              isPending={jiraTicketIsPending}
              isDisabled={!isReviewed}
              onPress={() => {
                localStorage.setItem(
                  "knowledgeArticle" + caseId,
                  ref.current?.getMarkdown() || "",
                );
                createKbArticle(ref.current?.getMarkdown() || "");
                setIsViewList(true);
              }}
            >
              Save & Submit
            </Button>
          </Flex>
          <Flex>
            <Button
              variant="primary"
              onPress={() => {
                setIsReview(false);
                setIsViewList(true);
              }}
              isDisabled={jiraTicketIsPending}
            >
              Cancel
            </Button>
          </Flex>
        </Flex>
      </Flex>
    );

  return (
    <View>
      <Flex direction="column">
        <View UNSAFE_className="response">
          <ReactMarkdown remarkPlugins={[remarkGfm]}>
            {aiArticleText}
          </ReactMarkdown>
          {(aiArticleIsLoading || !aiArticleText) && (
            <ProgressCircle aria-label="Loading…" isIndeterminate />
          )}
          <Flex
            direction="row"
            gap="size-200"
            wrap="wrap"
            marginTop={"size-500"}
          >
            <Flex>
              <ActionButton
                isDisabled={aiArticleIsLoading}
                onPress={() => {
                  navigator.clipboard
                    .writeText(aiArticleText || "")
                    .then(() =>
                      ToastQueue.positive("Copied to clipboard", {
                        timeout: 3000,
                      }),
                    )
                    .catch(() =>
                      ToastQueue.negative("Failed to copy to clipboard", {
                        timeout: 5000,
                      }),
                    );
                }}
                UNSAFE_style={{ paddingRight: "10px" }}
              >
                <Copy /> Copy
              </ActionButton>
            </Flex>
            <Flex>
              <ActionButton
                isDisabled={aiArticleIsLoading}
                onPress={() => reload()}
                UNSAFE_style={{ paddingRight: "10px" }}
              >
                <Refresh /> Refresh
              </ActionButton>
            </Flex>
            <Flex>
              <ActionButton
                isDisabled={aiArticleIsLoading}
                onPress={() => {
                  setIsReview(true);
                  setIsReviewed(false);
                  setIsViewList(false);
                  localStorage.setItem(
                    "knowledgeArticle" + caseId,
                    aiArticleText || "",
                  );
                }}
                UNSAFE_style={{ paddingRight: "10px" }}
              >
                <Preview /> Edit & Submit
              </ActionButton>
            </Flex>
          </Flex>
        </View>
      </Flex>
    </View>
  );
};

export default AiKnowledgeCenter;
