import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { View } from "@adobe/react-spectrum";
import "../../styles/main.scss"; // Make sure to create this Sass file
import BoxIcon from "@spectrum-icons/workflow/Box";
import LinkIcon from "@spectrum-icons/workflow/Link";
import RailBottomIcon from "@spectrum-icons/workflow/RailBottom";
import ChevronRightIcon from "@spectrum-icons/workflow/ChevronRight";
import { SidebarProps } from "../../types/ISideNavbar";

const SideNavbar: React.FC<SidebarProps> = ({ config }) => {
  // Initialize openGroup state with all group names
  const [openGroup, setOpenGroup] = useState(Object.keys(config));
  const activeLinkClass = "sidebar-item analytics-track-me active-style-Link";
  const sidebarItemClass = "sidebar-item analytics-track-me";

  const toggleGroup = (groupName: string) => {
    setOpenGroup((prevOpenGroups) => {
      if (prevOpenGroups.includes(groupName)) {
        return prevOpenGroups.filter((group) => group !== groupName);
      } else {
        return [...prevOpenGroups, groupName];
      }
    });
  };

  const getIconRotationStyle = (groupName: string) => ({
    transform: openGroup.includes(groupName) ? "rotate(90deg)" : "rotate(0deg)",
    transition: "transform 0.3s ease",
  });

  return (
    <View padding="size-400">
      <nav className="sidebar">
        {Object.entries(config).map(([groupName, items]) => (
          <div key={groupName} className="sidebar-group">
            <button
              className="sidebar-group-heading analytics-track-me"
              data-analytics-view-name="Sidebar"
              onClick={() => toggleGroup(groupName)}
            >
              {groupName === "OASIS" ? (
                <BoxIcon
                  aria-label=""
                  UNSAFE_className="sidebar-group-items-icon"
                />
              ) : groupName === "External Links" ? (
                <LinkIcon
                  aria-label=""
                  UNSAFE_className="sidebar-group-items-icon"
                />
              ) : (
                <RailBottomIcon
                  aria-label=""
                  UNSAFE_className="sidebar-group-items-icon"
                />
              )}
              {groupName}
              <ChevronRightIcon
                aria-label=""
                UNSAFE_className="sidebar-group-toggle-icon"
                UNSAFE_style={getIconRotationStyle(groupName)}
              />
            </button>
            {openGroup.includes(groupName) && (
              <div className="sidebar-group-items">
                {items.map((item, index) => (
                  <NavLink
                    key={`${groupName}_${index}`}
                    className={({ isActive }) =>
                      isActive ? activeLinkClass : sidebarItemClass
                    }
                    to={item.LinkURL}
                    target={groupName === "OASIS" ? "_self" : "_blank"}
                    rel={
                      groupName !== "OASIS" ? "noopener noreferrer" : undefined
                    }
                    data-analytics-link-name={item.LinkName}
                    data-analytics-link-type={
                      groupName !== "OASIS" ? "exit" : ""
                    }
                    data-analytics-view-name="Sidebar"
                  >
                    {item.LinkName}
                  </NavLink>
                ))}
              </div>
            )}
          </div>
        ))}
      </nav>
    </View>
  );
};

export default SideNavbar;
