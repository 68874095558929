import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import DOMPurify from "dompurify";

interface MarkdownRendererProps {
  text: string;
}

const customLinkRenderer = (props: any) => {
  const { href = "" } = props;
  try {
    const url = new URL(href);
    return url.hostname.endsWith("adobe.com") ? (
      <a {...props}>{props.children}</a>
    ) : (
      <span>{href}</span>
    );
  } catch {
    return null;
  }
};

const customImageRenderer = (props: any) => {
  return null;
};

const AiMarkdownRenderer: React.FC<MarkdownRendererProps> = ({ text }) => {
  const sanitizedText = DOMPurify.sanitize(text);

  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      components={{
        a: customLinkRenderer,
        img: customImageRenderer,
      }}
    >
      {sanitizedText}
    </ReactMarkdown>
  );
};

export default AiMarkdownRenderer;
