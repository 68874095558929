export function formatDate(date: string): string {
  if (date === "") return "";
  const formatter = new Intl.DateTimeFormat(["en"], {
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZoneName: "short",
    timeZone: "UTC",
  });
  return formatter.format(new Date(date));
}

export function formatDateLocal(date: string): string {
  if (date === "") return "";
  const formatter = new Intl.DateTimeFormat(["en"], {
    month: "short", // Abbreviated month name
    day: "numeric", // Day of the month
    year: "numeric", // Full year
    hour: "numeric", // Hour
    minute: "numeric", // Minute
    hour12: true, // AM/PM format
    timeZoneName: "short", // Time zone abbreviation
  });
  return formatter.format(new Date(date));
}

export function getDate(inputString: string) {
  const date = new Date(inputString);
  const year = date.getFullYear().toString().slice(-2);
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${month}/${day}/${year}`;
}
