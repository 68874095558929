import { createSlice } from "@reduxjs/toolkit";
import { TempAdmin } from "../../types/tempAdmin";

const initialState: TempAdmin = {
  message: "",
  error: "",
  isLoaded: true,
  workInProgress: false,
  user: {
    id: 0,
    project: "",
    environment: "",
    email: "",
    password: "",
    username: "",
    ticket: "",
    adminUrl: "",
    adminUri: "",
  },
  users: [],
  zd: {},
  ticketStatus: "",
  status: 0,
  data: {},
  orgId: "",
  rengaId: "",
  pollingEnabled: false,
  requestCount: 0,
  deleteIsLoading: false,
};

const tempAdminSlice = createSlice({
  name: "tempAdmin",
  initialState,
  reducers: {
    setTempAdmin: (state, action) => {
      state.user = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    setWorkInProgress: (state, action) => {
      state.workInProgress = action.payload;
    },
    setTicketStatus: (state, action) => {
      state.ticketStatus = action.payload;
    },
    setIsLoaded: (state, action) => {
      state.isLoaded = action.payload;
    },
    setPollingEnabled: (state, action) => {
      state.pollingEnabled = action.payload;
    },
    setRequestCount: (state, action) => {
      state.requestCount = action.payload;
    },
    setDeleteIsLoading: (state, action) => {
      state.deleteIsLoading = action.payload;
    },
  },
});

export const {
  setTempAdmin,
  setTicketStatus,
  setWorkInProgress,
  setError,
  setIsLoaded,
  setPollingEnabled,
  setRequestCount,
  setDeleteIsLoading,
  setErrorMessage,
} = tempAdminSlice.actions;
export default tempAdminSlice.reducer;
