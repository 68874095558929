import React from "react";
import {
  Button,
  // ButtonGroup,
  Content,
  Dialog,
  DialogTrigger,
  Divider,
  Flex,
  // Footer
  Header,
  Heading,
  Text,
  View,
} from "@adobe/react-spectrum";
import Alert from "@spectrum-icons/workflow/Alert";
import { InternalNote } from "../../types/case";

interface OrgAlertsProps {
  internalNotes: InternalNote[];
}
const formatDate = (dateString: string): string => {
  const date = new Date(dateString);

  const day = new Intl.DateTimeFormat("en-US", { day: "2-digit" }).format(date);
  const month = new Intl.DateTimeFormat("en-US", { month: "short" }).format(
    date,
  );
  const year = new Intl.DateTimeFormat("en-US", { year: "numeric" }).format(
    date,
  );

  return `${day}-${month}-${year}`;
};

const OrgNotes: React.FC<OrgAlertsProps> = ({ internalNotes }) => (
  <DialogTrigger type={"popover"}>
    <Button
      variant={"overBackground"}
      justifySelf={"start"}
      UNSAFE_style={{
        cursor: "pointer",
        padding: 0,
        margin: "0 0 0 10px",
        display: "flex",
        border: 0,
        alignItems: "baseline",
      }}
    >
      <Alert aria-label="Negative Alert" color="negative" size="XS" />
    </Button>
    <Dialog UNSAFE_style={{ fontFamily: "AdobeClean" }} maxWidth={"size-6000"}>
      <Heading>
        <Flex alignItems="center" gap="size-100">
          <Text>Organization Level Notes</Text>
        </Flex>
      </Heading>
      <Header>
        <Flex
          UNSAFE_style={{
            padding: 0,
            margin: "0 0 0 10px",
            display: "flex",
            border: 0,
            alignItems: "baseline",
            backgroundColor: "red",
            borderRadius: "50%",
            minWidth: "20px",
            minHeight: "20px",
            color: "white",
            justifyContent: "center",
            fontWeight: "bold",
          }}
        >
          <Text>{internalNotes?.length}</Text>
        </Flex>
      </Header>
      <Divider marginBottom={"size-100"} marginTop={"size-100"} />
      <Content maxHeight={"size-4600"} maxWidth={"size-4600"}>
        {internalNotes?.map((note, index) => (
          <View key={index}>
            <Flex justifyContent="space-between">
              <Heading
                level={4}
                margin={"size-50"}
                marginStart={0}
                maxWidth={"size-3000"}
              >
                <Text>
                  Note {index + 1}: <Text>{note.note_id}</Text>
                </Text>
              </Heading>
              <Text
                UNSAFE_style={{
                  font: "normal normal normal 10px/13px Adobe Clean",
                }}
              >
                {formatDate(note.created_on)}
              </Text>
            </Flex>
            <Flex>
              <Heading level={5} margin={"size-50"} marginStart={0}>
                <Text>{note.subject}</Text>
              </Heading>
            </Flex>
            <Text
              UNSAFE_style={{
                font: "normal normal normal 12px/16px Adobe Clean",
              }}
            >
              {note.description}
            </Text>
            {internalNotes?.length - 1 > index && (
              <Divider
                size={"S"}
                marginBottom={"size-150"}
                marginTop={"size-150"}
              />
            )}
          </View>
        ))}
      </Content>
      {/* This code is intentionally has been kept   */}
      {/*<Footer UNSAFE_style={{justifyContent: "center"}}>*/}
      {/*    <ButtonGroup >*/}
      {/*        <Button variant="secondary" UNSAFE_style={{cursor: "pointer"}}>View All</Button>*/}
      {/*    </ButtonGroup>*/}
      {/*</Footer>*/}
    </Dialog>
  </DialogTrigger>
);

export default OrgNotes;
