export function extractPrefixFromEntitledProduct(
  entitledProduct?: string,
): string {
  if (entitledProduct === undefined) {
    return "";
  }

  const parts: string[] = entitledProduct.split(/\s*-\s*/);

  // Check if the entitled product is in the expected format (single hyphen separating product name and prefix)
  // and if the prefix contains any non-alphanumeric characters
  if (parts.length !== 2 || !/^[a-zA-Z0-9]+$/.test(parts[1])) {
    return "";
  }

  return parts[1];
}
