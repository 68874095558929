import React from "react";
import { View, Picker, Item } from "@adobe/react-spectrum";
import useBranchSelector from "../../../hooks/useBranchSelector";

const BranchSelector: React.FC = () => {
  const { chooseEnvironmentHandler, dumpObject } = useBranchSelector();

  return (
    <View>
      {dumpObject?.environmentList && (
        <Picker
          label="Branch:"
          items={dumpObject.environmentList}
          selectedKey={dumpObject.chosenBranch}
          onSelectionChange={(selected) => {
            if (selected) {
              chooseEnvironmentHandler(String(selected));
            }
          }}
        >
          {(item) => <Item>{item["title"]}</Item>}
        </Picker>
      )}
    </View>
  );
};

export default BranchSelector;
