import { View, Flex } from "@adobe/react-spectrum";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/svg/adobe_logo.svg";
import Tag from "../../assets/svg/Tag.svg";
import ProfilePicture from "../../assets/profile.png";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import Banner from "./Banner";

// Define the interface for the component's props
interface NavbarProps {
  sticky?: boolean;
}

// Apply the interface to your component props
const Navbar: React.FC<NavbarProps> = ({ sticky = false }) => {
  const user = useSelector((state: RootState) => state.user.userDetails);
  const navigate = useNavigate();
  return (
    <View UNSAFE_className="sticky-navbar" height={"single-line-height"}>
      <Flex
        alignItems="center"
        justifyContent="start"
        direction="row"
        height="100%"
      >
        <div
          className="navbar-adobeLogo-div"
          onClick={() => navigate("/", { replace: true })}
        >
          <img className="navbar-adobeLogo" src={logo} alt=""></img>
        </div>
        <div
          className="navbar-left-col-title"
          onClick={() => navigate("/", { replace: true })}
        >
          DX Oasis
        </div>

        <Flex UNSAFE_className="navbar-left-col-Beta">
          <img src={Tag} alt="" aria-label="in Beta" />
        </Flex>
      </Flex>
      <Banner />

      <Flex alignItems="center">
        <span className="navbar-right-col-icons" id="navbar-user-name">
          {user?.name}
        </span>
        <img
          className="navbar-avatar"
          alt=""
          src={`https://s7d2.scene7.com/is/image/IMGDIR/${user?.userid || ""}?$70x$`}
          onError={(ev) => (ev.currentTarget.src = ProfilePicture)}
        />
      </Flex>
    </View>
  );
};

export default Navbar;
