import React from "react";
import FormView from "../../common/FormView";
import { ProgressCircle, View } from "@adobe/react-spectrum";
import DeleteButton from "./DeleteButton";

const CollectingDumps: React.FC = () => {
  return (
    <FormView
      title={"Deploy Dumps"}
      body={
        <View>
          <p>Collecting dump files</p>
          <ProgressCircle size="S" aria-label="Loading…" isIndeterminate />
        </View>
      }
      footerInfo={"Downloading dump files, please wait..."}
      footerButtonComponent={<DeleteButton />}
    />
  );
};

export default CollectingDumps;
