import React from "react";
import {
  ActionButton,
  Content,
  Dialog,
  DialogTrigger,
  Divider,
  Flex,
  Heading,
  IconProps,
  InlineAlert,
  Radio,
  RadioGroup,
  Text,
  ToggleButton,
} from "@adobe/react-spectrum";
import CheckmarkCircle from "@spectrum-icons/workflow/CheckmarkCircle";
import Wrench from "@spectrum-icons/workflow/Wrench";
import Alert from "@spectrum-icons/workflow/Alert";
import History from "@spectrum-icons/workflow/History";
import { useProductStatusQuery } from "../../services/supportInsights";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { ProductStatusResponse } from "../../types/datapoints";
import familyNameToCode from "../../utils/familyNameToCode";
import { formatDateLocal } from "../../utils/formatDate";

const DetailedStatus: React.FC<{ event: ProductStatusResponse }> = ({
  event,
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [showHistory, setShowHistory] = React.useState(false);
  return (
    <InlineAlert variant={alertVariantFromStatus(event)}>
      <Heading>
        {event.type} &bull; {event.status}
      </Heading>
      <Content>
        <Flex direction="row" gap="size-100">
          <Flex direction="column" flex="1">
            <span>
              <strong>{event.productName}</strong>
            </span>
            <span>
              <strong>{event.type}</strong>&nbsp;#{event.id}
            </span>
            <span>
              <strong>Status:</strong>&nbsp;{event.status}
            </span>
          </Flex>
          <Divider orientation="vertical" size="M" />
          <Flex direction="column" flex="2" width="70%">
            <span>
              <strong>Regions:</strong> {event.regions.join(", ")}
            </span>
            {!["Scheduled", "Started"].includes(event.status) &&
              event.endedOn && (
                <span>
                  <strong>Started/Ended:</strong>{" "}
                  {formatDateLocal(event.startedOn + "Z")} -{" "}
                  {formatDateLocal(event.endedOn + "Z")}
                </span>
              )}
            {!["Scheduled", "Started"].includes(event.status) &&
              !event.endedOn && (
                <span>
                  <strong>Started:</strong>{" "}
                  {formatDateLocal(event.startedOn + "Z")}
                </span>
              )}
            {["Scheduled", "Started"].includes(event.status) && (
              <span>
                <strong>Expected duration:</strong>{" "}
                {formatDateLocal(event.startedOn + "Z")} -{" "}
                {formatDateLocal(event.endedOn + "Z")}
              </span>
            )}
          </Flex>
        </Flex>
        <Flex direction={"column"} gap="size-100" marginTop="size-100">
          <ToggleButton
            isSelected={showHistory}
            alignSelf="end"
            onChange={() => setShowHistory(!showHistory)}
            UNSAFE_className="analytics-track-me"
            data-analytics-view-name="Solution Status"
            data-analytics-link-name="Toggle history"
          >
            <History />
            <Text>Show History</Text>
          </ToggleButton>
          {showHistory &&
            event.history.map((event) => (
              <InlineAlert>
                <Heading>{formatDateLocal(event.messageTime + "Z")}</Heading>
                <Content>
                  <Flex direction="column" flex="1">
                    <span>
                      <strong>Update:</strong> {event.message}
                    </span>
                    <span>
                      <strong>Impact Severity:</strong> {event.impactLevel}
                    </span>
                    <span>
                      <strong>Impact Scope:</strong> {event.impactScope}
                    </span>
                    <span>
                      <strong>Services Impacted:</strong>{" "}
                      {event.servicesImpacted.join(", ")}
                    </span>
                  </Flex>
                </Content>
              </InlineAlert>
            ))}
        </Flex>
      </Content>
    </InlineAlert>
  );
};

const Indicator: React.FC<
  React.PropsWithChildren<{
    events: ProductStatusResponse[];
    variant?: "negative" | "positive" | "informative" | "notice";
  }>
> = ({ events, variant = "negative", children }) => {
  const [showHistory, setShowHistory] = React.useState<"today" | "40d">(
    "today",
  );

  const hydrate = (event: ProductStatusResponse) => (
    <DetailedStatus event={event} />
  );
  const handleEmpty = (content: React.ReactElement[] = []) =>
    content.length ? (
      content
    ) : (
      <InlineAlert variant="neutral">No events found</InlineAlert>
    );

  const detailedEvents = events
    .filter((event) => {
      if (
        showHistory === "today" &&
        (event.status === "Closed" || event.status === "Completed")
      ) {
        return isToday(event.endedOn + "Z");
      }
      return true;
    })
    .sort((a, b) => {
      const astart = Date.parse(a.startedOn);
      const bstart = Date.parse(b.startedOn);
      return bstart - astart;
    });

  return (
    <Flex direction="column" marginTop="size-50">
      <span className="ticket-info__section-subtitle">Adobe Status</span>
      <DialogTrigger type="modal">
        <ActionButton
          isQuiet
          alignSelf="start"
          UNSAFE_className="analytics-track-me"
          data-analytics-view-name="Solution Status"
          data-analytics-link-name="Solution Status trigger"
        >
          <Flex
            gap="size-50"
            UNSAFE_style={{ fill: variantToColor(variant) }}
            UNSAFE_className="indicator__label"
          >
            {children}
          </Flex>
        </ActionButton>
        <Dialog isDismissable size="L">
          <Heading>Events</Heading>
          <Divider />
          <Content>
            <Flex direction="column" gap="size-100">
              <RadioGroup
                orientation="horizontal"
                value={showHistory}
                onChange={(key) => setShowHistory(key as "today" | "40d")}
              >
                <Radio value="today">Current</Radio>
                <Radio value="40d">Last 40 days</Radio>
              </RadioGroup>
              {detailedEvents.length ? (
                <Flex direction="column" gap="size-100">
                  {handleEmpty(
                    detailedEvents
                      .filter((event) => event.status !== "Scheduled")
                      .map(hydrate),
                  )}
                  <Heading>Scheduled</Heading>
                  {handleEmpty(
                    detailedEvents
                      .filter((event) => event.status === "Scheduled")
                      .map(hydrate),
                  )}
                </Flex>
              ) : (
                handleEmpty()
              )}
            </Flex>
          </Content>
        </Dialog>
      </DialogTrigger>
    </Flex>
  );
};

const keysSortOrder = [
  "Potential",
  "Trivial",
  "Minor",
  "Major",
] as const satisfies readonly ProductStatusResponse["history"][0]["impactLevel"][];

const SolutionStatusLogician: React.FC = () => {
  const productFamily =
    useSelector(
      (state: RootState) => state.case.caseObject?.productFamilyName,
    ) ?? "";
  const { data: events, error } = useProductStatusQuery({
    productFamily: familyNameToCode(productFamily),
  });

  if (!productFamily) {
    return (
      <Indicator events={[]}>
        <Text>Loading...</Text>
      </Indicator>
    );
  }

  if (error) {
    return (
      <Indicator events={[]}>
        <Alert size="XS" />
        <Text>Status unknown</Text>
      </Indicator>
    );
  }

  if (!events) {
    return (
      <Indicator events={[]}>
        <Text>Loading...</Text>
      </Indicator>
    );
  }

  const issues = events.filter((event) => event.type === "Issue");
  const maintenance = events.filter((event) => event.type === "Maintenance");
  const opened = issues.filter((event) => event.status === "Opened");

  // There is at least one issue with the status 'Opened'
  if (opened.length) {
    type Key = (typeof events)[0]["history"][0]["impactLevel"];

    const impactLevelsMap = opened.map((val) =>
      getLeadingKey(val.history, keysSortOrder, (el) => el.impactLevel),
    );

    const highestImpact = getLeadingKey(
      impactLevelsMap,
      keysSortOrder,
      (el) => el,
    );

    const colorMap: Record<Key, NonNullable<IconProps["color"]>> = {
      Potential: "notice",
      Trivial: "notice",
      Minor: "notice",
      Major: "negative",
    };

    return (
      <Indicator events={events} variant={colorMap[highestImpact]}>
        <Alert size="XS" />
        <Text>Issue in progress ({highestImpact})</Text>
      </Indicator>
    );
  }

  // There are no 'Opened' issues, but there is maintenance with the status 'Started'
  if (maintenance.some((event) => event.status === "Started")) {
    return (
      <Indicator events={events} variant="informative">
        <Wrench size="XS" />
        <Text>Maintenance in progress</Text>
      </Indicator>
    );
  }

  // There are issues with the status Closed and endedOn date today
  if (
    issues.some(
      (event) => event.status === "Closed" && isToday(event.endedOn + "Z"),
    )
  ) {
    return (
      <Indicator events={events} variant="positive">
        <Alert size="XS" />
        <Text>Issue closed</Text>
      </Indicator>
    );
  }

  // There is maintenance with the status 'Completed' and 'endedOn' date today
  if (
    maintenance.some(
      (event) => event.status === "Completed" && isToday(event.endedOn + "Z"),
    )
  ) {
    return (
      <Indicator events={events} variant="informative">
        <Wrench size="XS" />
        <Text>Maintenance completed</Text>
      </Indicator>
    );
  }
  // No issues and maintenance for today, but there are maintenance with the status 'Scheduled' for future dates.
  if (
    maintenance.some(
      (event) =>
        event.status === "Scheduled" &&
        Date.parse(event.startedOn) > Date.now(),
    )
  ) {
    return (
      <Indicator events={events} variant="informative">
        <Wrench size="XS" />
        <Text>Maintenance scheduled</Text>
      </Indicator>
    );
  }

  // Otherwise...
  return (
    <Indicator events={events} variant="informative">
      <CheckmarkCircle size="XS" />
      <Text>Available</Text>
    </Indicator>
  );
};

function isToday(date: string) {
  const givenDate = new Date(date);
  const today = new Date();

  return (
    givenDate.getDate() === today.getDate() &&
    givenDate.getMonth() === today.getMonth() &&
    givenDate.getFullYear() === today.getFullYear()
  );
}

function getLeadingKey<T, K>(
  arr: T[],
  keysOrder: readonly K[],
  keyExtractor: (el: T) => K,
) {
  const maxIndex = arr.reduce((acc, val) => {
    return acc > keysOrder.indexOf(keyExtractor(val))
      ? acc
      : keysOrder.indexOf(keyExtractor(val));
  }, 0);
  const highestImpact = keysOrder[maxIndex];
  return highestImpact;
}

function alertVariantFromStatus(event: ProductStatusResponse) {
  if (event.type === "Maintenance") {
    // Green for completed maintenance, blue for scheduled, red for ongoing
    switch (event.status) {
      case "Completed":
        return "positive";
      case "Started":
        return "notice";
      default:
        return "info";
    }
  }

  switch (event.status) {
    case "Completed":
    case "Closed":
      return "positive";
    case "Opened": {
      const impactLevels = event.history.map((event) => event.impactLevel);
      const maxImpact = getLeadingKey(impactLevels, keysSortOrder, (el) => el);
      return maxImpact === "Major" ? "negative" : "notice";
    }
    case "Scheduled":
    case "Started":
      return "notice";
    default:
      return "info";
  }
}

function variantToColor(
  variant: "negative" | "positive" | "informative" | "notice",
) {
  switch (variant) {
    case "negative":
      return "var(--spectrum-negative-visual-color)";
    case "positive":
      return "var(--spectrum-positive-visual-color)";
    case "informative":
      return "var(--spectrum-informative-visual-color)";
    case "notice":
      return "var(--spectrum-notice-visual-color)";
  }
}

export default SolutionStatusLogician;
