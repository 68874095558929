import React from "react";
import PageWrapper from "../components/common/PageWrapper";
import CreateAdmin from "../components/commerceSupportTools/CreateAdmin";

const CreateTemporaryAdmin: React.FC = () => {
  document.title = "Create Temporary Admin";
  return (
    <PageWrapper pageName={"Create Temporary Admin"}>
      <CreateAdmin />
    </PageWrapper>
  );
};

export default CreateTemporaryAdmin;
