export interface AiPrompt {
  label: string;
  prompt: string | null;
}

export const promptMatrix = {
  "investigate-summary-commerce-project": {
    label: "Project Summary",
    prompt:
      "Provide instance summary. Use the following format for your response:\n" +
      "## Project Summary\n" +
      "- **Instance URL:** (Project URL)\n" +
      "- **Adobe Commerce:** (AC value)\n" +
      "- **HIPAA Compliance:** (value)\n" +
      "- **B2B Version:** (value)\n" +
      "- **ECE Version:** (value)\n" +
      "- **Mage ID:** (value)\n" +
      "- **Cluster Type:** (value)\n" +
      "### Services:\n" +
      "- **PHP:** (value) if (PHP EOL Date) print '*Expired on (PHP EOL Date)*'\n" +
      "- **MySQL:** (value) if (MySQL EOL Date) print '*Expired on (MySQL EOL Date)*'\n" +
      "- **Redis:** (value) if (Redis EOL Date) print '*Expired on (Redis EOL Date)*'\n" +
      "- **Elasticsearch:** (value) if (Elasticsearch EOL Date) print '*Expired on (Elasticsearch EOL Date)*'\n",
  },
  "investigate-summary-quick": {
    label: "Quick Summary",
    prompt:
      "Summarize the customer case in the following format:\n" +
      "#### Summarized Description:\n" +
      "text\n" +
      "- **Customer Impact:**\n" +
      "- **Customer Communication:**\n" +
      "- **Sentiment Analysis:**\n" +
      "- **Steps Taken:**\n" +
      "- **Status and Resolution:**\n" +
      "- **Final Outcome:**\n" +
      "Ensure that each item is a block of Markdown text and includes the names of relevant individuals.",
  },
  "investigate-summary-detailed": {
    label: "Detailed Summary",
    prompt:
      "Provide a detailed summary of the customer case, and include relevant links.",
  },
  "investigate-summary-soap": {
    label: "SOAP Summary",
    prompt:
      "Summarize the customer case in the following format:\n" +
      "- **Scope:** (Define the issue/question on the ticket, as specific as possible)\n" +
      "- **Objective:** (Define the expected outcome)\n" +
      "- **Actions:** (Define the specific actions performed by the support engineer and customer)\n" +
      "- **Plan:** (Describe future actions to be done by the support engineer and customer)\n" +
      "- **Business Impact:** (Describe business impact)\n" +
      "- **Current Work Around:** (Describe current work around)\n" +
      "- **Next Steps:** (Describe next steps)\n" +
      "Ensure that each item includes the names of relevant individuals.",
  },
  "investigate-summary-cadence": {
    label: "Cadence Summary",
    prompt:
      "Show the cadence summary of the customer case indicating who responded and when, along with where the delay " +
      'in the response occurred, and the time delay between each response. Calculate "Time Delay" column as the ' +
      "absolute difference of the UTC between the previous and next response.Include this information in one table " +
      'organized chronologically. Format "Date and Time (UTC)" column as "yyyy-MM-dd HH:mm:ss".\n' +
      "Here is an example of your response:\n" +
      "Based on the provided customer case, here is the cadence summary indicating who responded and when, along " +
      "with where the delay in the response occurred, and time delay between each response: \n\n" +
      "| **Responder**      | **Role**     | **Date and Time (UTC)**   | ** Summary**     | **Time Delay (Xd Xh Xm)**  |\n" +
      "|--------------------|--------------|---------------------------|------------------|----------------------------|\n" +
      "| ...                | ...          | ...                       | ...              | ...                        |\n" +
      "### Observations:\n" +
      "- The longest delay occurred between ...\n" +
      "- The shortest delay was from ...\n" +
      "This table summarizes the interactions and highlights the delay in the response process.",
  },
  "suggest-resolution": {
    label: "Suggest Resolution",
    prompt: null,
  },
  "other-knowledge-center": {
    label: "Knowledge Center",
    prompt: null,
  },
  "investigate-custom-prompt": {
    label: "Custom Prompt",
    prompt: null,
  },
} satisfies Record<string, AiPrompt>;

export type PromptMatrixKey = keyof typeof promptMatrix;

export const suggestionsKeys: PromptMatrixKey[] = [
  "investigate-summary-commerce-project",
  "investigate-summary-quick",
  "investigate-summary-detailed",
  "investigate-summary-soap",
  "investigate-summary-cadence",
];
