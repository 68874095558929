import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setIsDataCollecting } from "../store/case/caseSlice";
import {
  DataMetaResponse,
  DataPointsResponse,
  ProductStatusResponse,
  DynamicsDataPoint,
} from "../types/datapoints";
import { DynamicsCollectMutationArgument } from "../types/dynamicsCollect";
import { IsProductFamilySupported } from "../types/isProductFamilySupported";
import {
  CaseCoulumnFilterRequest,
  CaseCoulumnFilterResponse,
  CaseResponse,
  GetTicketResponse,
  DataObjectWithToken,
  UserRoleResponse,
  BannerResponse,
} from "../types/case";
import { SearchRequest } from "../constants/search";

export const supportInsightsApi = createApi({
  reducerPath: "supportInsightsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: window.env.REACT_APP_API_BASE,
    timeout: 600000,
  }),
  tagTypes: ["Datapoints", "Findings"],
  endpoints: (builder) => ({
    getTicketList: builder.mutation<CaseResponse, SearchRequest>({
      query: (params: SearchRequest) => {
        return {
          url: `dynamics/search`,
          method: "post",
          body: params,
          headers: {
            "Content-type": "application/json",
          },
        };
      },
    }),
    postBannerData: builder.mutation<void, DataObjectWithToken>({
      query: ({ dataObject, token }) => ({
        url: "/application/settings",
        method: "POST",
        body: dataObject,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }),
    }),
    updateBannerData: builder.mutation({
      query: ({ id, dataObject, token }) => ({
        url: `/application/settings/${id}`,
        method: "PUT",
        body: dataObject,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
    getUserBannerData: builder.query<
      BannerResponse[],
      { CODE: string; token: string }
    >({
      query: ({ CODE, token }) => ({
        url: `/application/settings?code=${CODE}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
    deleteBanner: builder.mutation<void, { id: number; token: string }>({
      query: ({ id, token }) => ({
        url: `/application/settings/${id}`,
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
    getTicketFilterColumn: builder.mutation<
      CaseCoulumnFilterResponse,
      CaseCoulumnFilterRequest
    >({
      query: (params) => {
        return {
          url: `dynamics/search/filter`,
          method: "post",
          body: params,
          headers: {
            "Content-type": "application/json",
          },
        };
      },
    }),
    getUserRole: builder.query<UserRoleResponse, string>({
      query: (token) => ({
        url: "/user/role",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }),
    }),
    getTicketById: builder.query<GetTicketResponse, { ticketId: string }>({
      query: ({ ticketId }) => `dynamics/${ticketId}`,
    }),
    getTicketByFullInfo: builder.query<
      GetTicketResponse,
      { ticketId: string; orgId: string; rengaId: string }
    >({
      query: ({ ticketId, orgId, rengaId }) =>
        `dynamics/${ticketId}/org/${orgId}/rengaId/${rengaId}`,
    }),
    getDataPoints: builder.query<
      DataPointsResponse,
      { caseId: string; productFamily: string }
    >({
      query: ({ caseId, productFamily }) =>
        `products/${productFamily}/dynamics/${caseId}/datapoints`,
      providesTags: ["Datapoints"],
    }),
    getFindings: builder.query({
      query: ({
        caseId,
        productFamily,
      }: {
        caseId: string;
        productFamily: string;
      }) => `products/${productFamily}/dynamics/${caseId}/findings`,
      providesTags: ["Findings"],
    }),
    getFindingFilter: builder.query({
      query: ({
        caseId,
        productFamily,
        filterName,
      }: {
        caseId: string;
        productFamily: string;
        filterName: string;
      }) =>
        `products/${productFamily}/dynamics/${caseId}/findings/filter?filterName=${filterName}&page=1`,
    }),
    getIsProductFamilySupported: builder.query<
      IsProductFamilySupported,
      string
    >({
      query: (productFamilyCode: string) => `products/${productFamilyCode}`,
    }),
    getDataPointById: builder.query<
      DataMetaResponse<
        DynamicsDataPoint[],
        {
          product_family: string;
          ticket_id: string | null;
        }
      >,
      { datapointId: number }
    >({
      query: ({ datapointId }) => `datapoint/${datapointId}`,
    }),
    dynamicsCollectData: builder.mutation<
      DataMetaResponse<{ data_points: unknown[]; findings: unknown[] }>,
      DynamicsCollectMutationArgument
    >({
      query: ({ payload, productFamilyCode }) => ({
        url: `products/${productFamilyCode}/dynamics/collect`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Datapoints", "Findings"],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        dispatch(setIsDataCollecting(true));
        try {
          await queryFulfilled;
        } catch (error) {
          console.error(error);
          dispatch(setIsDataCollecting(false));
        } finally {
          dispatch(setIsDataCollecting(false));
        }
      },
    }),
    getDataPointByCode: builder.query<
      DataPointsResponse,
      { familyCode: string; ticketID: string; code: string }
    >({
      query: ({ familyCode, ticketID, code }) =>
        `products/${familyCode}/dynamics/${ticketID}/datapoints?code=${code}`,
    }),
    refreshTicket: builder.mutation<
      GetTicketResponse,
      { caseId: string; orgId: string; rengaId: string }
    >({
      query: ({ caseId, orgId, rengaId }) => ({
        url: `dynamics/${caseId}/org/${orgId}/rengaId/${rengaId}/refresh/`,
        method: "PUT",
      }),
    }),
    productStatus: builder.query<
      ProductStatusResponse[],
      { productFamily: string }
    >({
      query: ({ productFamily }) => ({
        url: `products/${productFamily}/adobeStatus`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetDataPointByIdQuery,
  useGetDataPointsQuery,
  useGetFindingsQuery,
  useGetFindingFilterQuery,
  useGetIsProductFamilySupportedQuery,
  useGetTicketByIdQuery,
  useDynamicsCollectDataMutation,
  useGetDataPointByCodeQuery,
  useGetTicketByFullInfoQuery,
  useRefreshTicketMutation,
  useProductStatusQuery,
  useGetTicketListMutation,
  useGetTicketFilterColumnMutation,
  usePostBannerDataMutation,
  useGetUserRoleQuery,
  useGetUserBannerDataQuery,
  useDeleteBannerMutation,
  useUpdateBannerDataMutation,
} = supportInsightsApi;
