import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/store";
import { useLazyCreateDumpQuery } from "../services/dumpTool";
import React, { useEffect } from "react";
import { Key, Selection } from "@adobe/react-spectrum";
import {
  dumpInProgress,
  dumpSuccess,
  mapError,
  setCheckedLinks,
  tableSizeInProgress,
} from "../store/dump/dumpSlice";
import getProjectId from "../utils/getProjectId";

export interface Item {
  [key: string]: string;
  table: string;
  size: string;
}

export const useSizeTableList = () => {
  const dispatch = useDispatch();
  const caseObject = useSelector((state: RootState) => state.case.caseObject);
  const productMetadata = caseObject?.productMetadata ?? [];
  const projectUrl =
    productMetadata.find((item) => item.displayName === "Project URL")?.value ||
    "";

  let projectId = "";
  if (projectUrl) {
    projectId = getProjectId(projectUrl);
  }

  const [createDump, { data: createDumpData, isLoading, error, isSuccess }] =
    useLazyCreateDumpQuery();
  const dumpObject = useSelector((state: RootState) => state.dump);
  const baseUrl = dumpObject.data.apiUrl;

  const user = useSelector(
    (state: RootState) => (state.user.userDetails?.userid as string) ?? "",
  );

  let columns = [
    { name: "Table", uid: "table" },
    { name: "Size", uid: "size" },
  ];

  let rows: Item[] = dumpObject.data.tableSize.items;
  let [selectedKeys, setSelectedKeys] = React.useState<Selection>(new Set([]));

  const onChange = (selectedKeys: Selection) => {
    setSelectedKeys(selectedKeys);
    let selectedKeysArray = transformToArray(selectedKeys);
    dispatch(setCheckedLinks(selectedKeysArray));
  };

  //convert selectedKeys to an array
  const transformToArray = (selectedKeys: Selection) => {
    let arrVal: Key[] = [];
    if (selectedKeys !== "all") {
      arrVal = Array.from(selectedKeys);
    } else if (selectedKeys == "all") {
      rows.forEach((item) => {
        arrVal.push(item.table);
      });
    }

    return arrVal;
  };

  const onAction = (selectedKeys: any) => {
    transformToArray(selectedKeys).join(",");
    let skippedTables = dumpObject.data.checkedLinks.join(",");
    let branch = dumpObject.data.chosenBranch;
    if (!branch) {
      dispatch(dumpInProgress("waiting for branch update"));
    }

    let queryString = "?requester=" + user;
    if (skippedTables) {
      queryString += "&skip_tables=" + skippedTables;
    }

    createDump({
      baseUrl: baseUrl,
      projectId: projectId,
      branch: branch,
      checkLinks: queryString,
    });
  };

  useEffect(() => {
    if (!isLoading && !error && createDumpData) {
      switch (createDumpData.dump_status) {
        case "collected":
          dispatch(dumpSuccess(createDumpData));
          break;
        case "collect_in_progress":
          dispatch(dumpInProgress(createDumpData.message));
          break;
        case "table_size_in_progress":
          dispatch(tableSizeInProgress());
          break;
        case "map_error":
          dispatch(mapError("Deploy dump error"));
          break;
      }
    }
  }, [isSuccess, isLoading, error, createDumpData]);

  return { onAction, selectedKeys, onChange, columns, rows, isLoading, error };
};
