import { useGetDataPointByIdQuery } from "../../services/supportInsights";
import { Link } from "@adobe/react-spectrum";
import React from "react";
import { Finding, FindingModalProps } from "../../types/finding";
import Modal from "./Modal";
import { DataAggregator } from "../../utils/dataAggregator";
import { DynamicsDataPoint } from "../../types/datapoints";
import notEmpty from "../../utils/notEmpty";
import { Format, convertAndCopyForFindings } from "../../utils/dataExportUtils";
import { parseAndSanitizeMarkdown } from "../../utils/sanitizeMarkdown";
import MarkdownRenderer from "../../utils/renderMarkdown";

const FindingModal: React.FC<FindingModalProps> = ({
  findings,
  caseId,
  productFamily,
}) => {
  const queries = findings
    .map((f) => f.datapoint_id)
    .map((datapointId) =>
      // eslint-disable-next-line react-hooks/rules-of-hooks -- Iterating over an unknown number of datapoints
      useGetDataPointByIdQuery({
        datapointId,
      }),
    );

  const data = Array.from(queries)
    .map((q) => q.data?.data)
    .filter(notEmpty)
    .flat();
  const aggregator = new DataAggregator({ data });

  const isLoading = queries.some((q) => q.isLoading);
  const error = queries.some((q) => q.error);
  const documentationContent =
    parseAndSanitizeMarkdown(findings[0].documentation) +
    `<img src="data:null;," onerror="hydrateLinks(this)">`;

  if (isLoading || error) {
    return <span>{findings[0].name}</span>;
  }

  return (
    <Modal
      dialogDownloadFun={convertAndCopyForFindings}
      dialogJSON={aggregator.aggregate()}
      dialogDownloadFormat={Format.pdf}
      dialogHeader={findings[0].name}
      dialogShowSourceBtn={false}
      contentType="Finding"
      trigger={
        <Link
          UNSAFE_className="analytics-track-me"
          data-analytics-link-name={findings[0].name}
          data-analytics-view-name="Findings List"
        >
          {findings[0].name}
        </Link>
      }
      dialogShowDownloadBtn={true} // FIXME
    >
      <h2 className="modal-section-title">Recommendation</h2>
      {formatFindings(findings, data)}
      <h2 className="modal-section-title">Documentation</h2>
      <MarkdownRenderer content={documentationContent} isSanitizedHTML />
    </Modal>
  );
};

export default FindingModal;

function formatFindings(findings: Finding[], dd: DynamicsDataPoint[]) {
  const { recommendation } = findings[0];
  let __html = parseAndSanitizeMarkdown(recommendation);
  const includesDatapoint = recommendation.includes("{{ datapoint }}");

  const aggregator = new DataAggregator();
  aggregator.add(dd.map((d) => d));

  const datapointHTMLID = crypto.randomUUID();

  if (includesDatapoint) {
    __html = __html.replace(
      "{{ datapoint }}",
      // HACK: Use something other than images to move the datapoint table
      `<img src="data:null;," onerror="hydrateAggregated(this)" data-replace-with="${datapointHTMLID}">`,
    );
  }

  const hydrateAggregated = () => {
    if (!includesDatapoint) return null;
    return <div id={datapointHTMLID}>{aggregator.render()}</div>;
  };
  return (
    <>
      {/*Content is Sanitized in step above*/}
      <MarkdownRenderer content={__html} isSanitizedHTML />
      <div hidden>{hydrateAggregated()}</div>
    </>
  );
}

// @ts-expect-error -- HACK: Use something other than images to move the datapoint table
window.hydrateAggregated ??= (element: HTMLElement) => {
  const uuid = element.getAttribute("data-replace-with");
  if (!uuid) return;
  const container = document.getElementById(uuid);
  if (!container) return;
  element.parentElement?.replaceChild(container, element);
};
