import { OptionsMenu } from "../components/common/DropDownFilter";
import { CaseObject } from "../types/case";

export interface SearchTableColumns {
  name: string;
  uid: string;
  width: number | null | string;
  filter: boolean;
  sortBy: boolean;
  filterList: OptionsMenu[];
}

export interface SearchResult {
  caseItems: CaseObject[];
  errorMessage: string;
  searchValue: string;
  totalItem: number;
}
export interface SearchResultTableProps {
  searchText?: string;
}
export type FilterCase = { [key: string | number]: string[] };
export type SortOrder = { [key: string | number]: string };

export interface SearchRequest {
  query: string;
  filter?: FilterCase;
  sortOrder?: SortOrder;
  page?: number;
}

export const COLUMNS_FINDING_TABLE = [
  {
    name: "Finding Name",
    uid: "name",
    width: "2fr",
    filter: false,
    sortBy: true,
    filterList: [],
  },
  {
    name: "Category",
    uid: "categories",
    width: "1fr",
    filter: true,
    sortBy: true,
    filterList: [],
  },
  {
    name: "Impact",
    uid: "impact",
    width: "1fr",
    filter: true,
    sortBy: true,
    filterList: [],
  },
  {
    name: "Documentation",
    uid: "documentation",
    width: "2fr",
    filter: false,
    sortBy: true,
    filterList: [],
  },
];
export const FINDING_PER_PAGE = 20;

export const defaultSearchResultValue = {
  caseItems: [],
  errorMessage: "",
  searchValue: "",
  totalItem: 0,
};
