import { IMenuItem } from "../../types/ISideNavbar";

const AdobeAnalyticsMenuData: IMenuItem[] = [
  {
    Solution: "Adobe Analytics",
    LinkName: "Dragonfly",
    LinkURL: "https://dragonfly.corp.adobe.com/",
    Contextual: "No",
    ContextualURLParameters: "",
    LinkDescription:
      "This tool is used to view the rank/scores of JIRAs in various Kanban boards. Helps determining the ETA of resolution.",
    PointOfContact: "",
    DocumentationLink: "http://dragonfly.corp.adobe.com/",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Adobe Analytics",
    LinkName: "Raw Tool",
    LinkURL: "https://praw.corp.adobe.com/",
    Contextual: "No",
    ContextualURLParameters: "",
    LinkDescription:
      "Helps pull raw data from Analytics backend to troubleshoot reporting issues.",
    PointOfContact: "",
    DocumentationLink: "https://praw.corp.adobe.com/",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Adobe Analytics",
    LinkName: "BAT",
    LinkURL: "https://ark.corp.adobe.com/bat",
    Contextual: "No",
    ContextualURLParameters: "",
    LinkDescription: "Creates JIRA tickets.",
    PointOfContact: "",
    DocumentationLink: "https://pum.corp.adobe.com/bat/newJiraIssue.php",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Adobe Analytics",
    LinkName: "COLT",
    LinkURL: "https://ark.corp.adobe.com/colt",
    Contextual: "No",
    ContextualURLParameters: "",
    LinkDescription:
      "Allows internal teams to login to Analytics accounts of the customers.",
    PointOfContact: "",
    DocumentationLink: "https://pum.corp.adobe.com/colt/",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Adobe Analytics",
    LinkName: "Dr Teeth",
    LinkURL: "https://oasis.pnw.omniture.com/drteeth/index.html",
    Contextual: "No",
    ContextualURLParameters: "",
    LinkDescription:
      "This is a collection of various tools that is used to view and update Analytics settings. Some of the tools help analyse the status of Analytics queues such as Classifications and APIs",
    PointOfContact: "",
    DocumentationLink: "https://oasis.lon5.omniture.com/drteeth/index.html",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Adobe Analytics",
    LinkName: "Edge Cert Manager",
    LinkURL: "http://edgecert.adobe.net/",
    Contextual: "No",
    ContextualURLParameters: "",
    LinkDescription:
      "helps create and update FPC implementation of Analytics customers.",
    PointOfContact: "",
    DocumentationLink: "https://edgecert.adobe.net/",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Adobe Analytics",
    LinkName: "Grafana",
    LinkURL:
      "https://mm2.adobe.net/grafana/d/000000440/livestream-customer-alerts?orgId=1&refresh=30s",
    Contextual: "No",
    ContextualURLParameters: "",
    LinkDescription: "Used to monitor the performance of Livestream APIs",
    PointOfContact: "",
    DocumentationLink:
      "https://mm2.adobe.net/grafana/d/000000440/livestream-customer-alerts?orgId=1&refresh=30s",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Adobe Analytics",
    LinkName: "Splunk",
    LinkURL: "https://splunk.or1.adobe.net/en-US/app/launcher/home",
    Contextual: "No",
    ContextualURLParameters: "",
    LinkDescription: "",
    PointOfContact: "",
    DocumentationLink: "",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Adobe Analytics",
    LinkName: "Columbus",
    LinkURL: "https://columbus.corp.adobe.com/",
    Contextual: "No",
    ContextualURLParameters: "",
    LinkDescription: "",
    PointOfContact: "",
    DocumentationLink: "",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Adobe Analytics",
    LinkName: "ABO / Login-As",
    LinkURL:
      "https://abo.services.adobe.com/customers/search?query={requestor_email}",
    Contextual: "No",
    ContextualURLParameters: "",
    LinkDescription: "",
    PointOfContact: "",
    DocumentationLink: "",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Adobe Analytics",
    LinkName: "Panorama",
    LinkURL: "https://pmg.corp.adobe.com/PanoramaECJ",
    Contextual: "No",
    ContextualURLParameters: "",
    LinkDescription: "",
    PointOfContact: "",
    DocumentationLink: "",
    LinkType: "External",
    ParameterType: "",
  },
];

export default AdobeAnalyticsMenuData;
