const getAPIEndpointUrl = (region?: string) => {
  let url = window.env.REACT_APP_DUMP_TOOL_API_US;

  if (!region) {
    return url;
  }

  let listOfEuropeProjects = [
    "eu",
    "eu-1",
    "eu-2",
    "eu-3",
    "eu-4",
    "eu-5",
    "ap",
    "ap-3",
    "ap-4",
  ];

  // Extracting only the region name.
  if (listOfEuropeProjects.includes(region.split(".")[0])) {
    url = window.env.REACT_APP_DUMP_TOOL_API_EU || "";
  }

  return url;
};

export default getAPIEndpointUrl;
