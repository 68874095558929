import { useGetDataPointByCodeQuery } from "../../services/supportInsights";
import familyNameToCode from "../../utils/familyNameToCode";
import { Flex, Link } from "@adobe/react-spectrum";
import { useDispatch, useSelector } from "react-redux";
import { DataPointsResponse } from "../../types/datapoints";
import { useEffect } from "react";
import { latestDataPointDate } from "../../utils/latestDataPointDate";
import { setLastAnalysisDate } from "../../store/lastAnalysisSlice";
import { RootState } from "../../store/store";

export const DSATCell = (family: string, ticketID: string) => {
  const dispatch = useDispatch();
  const dsatQuery = useDatapointQuery(family, ticketID, "bash-dsat");
  const predictiveDsatQuery = useDatapointQuery(
    family,
    ticketID,
    "bash-predictive-dsat",
  );
  const dsatContent = getContent(dsatQuery);
  const predictiveDsatContent = getContent(predictiveDsatQuery);
  const orgId = useSelector((state: RootState) => state.case.caseObject?.orgId);

  useEffect(() => {
    const datapoints = dsatQuery?.data?.data ?? [];
    const lastAnalysisDate = latestDataPointDate(datapoints);
    if (lastAnalysisDate) {
      dispatch(setLastAnalysisDate(lastAnalysisDate));
    }
  }, [dsatQuery.data, dispatch]);

  return (
    <div className="banner__dsat">
      <Flex gap="size-250">
        <span className="banner__dsat__summary">
          {"Historical: "}
          <strong
            style={{
              color: getColor({
                ...dsatQuery,
                txt: dsatContent,
              }),
            }}
          >
            {dsatContent}
          </strong>
        </span>
        <span className="banner__dsat__summary">
          <Link
            href={`https://app.powerbi.com/groups/me/apps/0889cfd6-13e1-4212-a9c0-676d906c2e47/reports/1a72c8e2-d0fb-4f41-a196-0940c89aa3c4/ReportSection387a7ae2178d394197e0?experience=power-bi&filter=_customer%2FIMSOrgID%20eq%20%27${orgId}%27`}
            target="_blank"
            UNSAFE_className="analytics-track-me"
            data-analytics-view-name="Header"
            data-analytics-link-name="Predictive DSAT"
            data-analytics-link-type="exit"
          >
            {"Predictive: "}
            <strong
              style={{
                color: getColor({
                  ...predictiveDsatQuery,
                  txt: predictiveDsatContent,
                }),
              }}
            >
              {predictiveDsatContent}
            </strong>
          </Link>
        </span>
      </Flex>
      <p>Updated daily at 8:30 a.m. PT.</p>
    </div>
  );
};

const useDatapointQuery = (family: string, ticketID: string, code: string) => {
  const { data, error, isLoading } = useGetDataPointByCodeQuery({
    familyCode: familyNameToCode(family),
    ticketID,
    code,
  });
  return { data, error, isLoading };
};

const getColor = ({
  txt,
  isLoading,
  error,
}: {
  txt: string;
  isLoading: boolean;
  error?: unknown;
}) => {
  if (isLoading) return "gray";
  if (error) return "red";
  const float = parseFloat(txt);
  if (Number.isNaN(float)) return "red";

  if (float < 30) return "green";
  if (float < 50) return "#F87431";
  return "red";
};

const getContent = ({
  data,
  isLoading,
  error,
}: {
  data?: DataPointsResponse;
  isLoading: boolean;
  error?: unknown;
}) => {
  const textValue = data?.data?.[data.data.length - 1]?.value || "";
  if (isLoading) return "...";
  if (error) return "N/A (an error occurred)";
  if (!textValue) return "No data";

  try {
    const percentage =
      JSON.parse(textValue)?.[0]?.DSAT ??
      JSON.parse(textValue)?.[0]?.predictiveDsat;
    if (typeof percentage !== "number") throw new Error("DSAT not found!");
    return percentage.toFixed() + "%";
  } catch {
    if (!textValue || textValue.startsWith("[")) return "No data";
  }
  return textValue;
};
