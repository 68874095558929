export default class BugReporter {
  static formURL =
    "https://wiki.corp.adobe.com/display/CESTRATINTELandOPS/Oasis+-+Report+a+Bug" as const;

  static generateUrl(
    { summary, description } = { summary: "", description: "" },
  ) {
    const url = new URL(BugReporter.formURL);
    url.searchParams.append("summary", summary);
    url.searchParams.append("description", description);
    return url;
  }
}
