import {
  Divider,
  Flex,
  Footer,
  Header,
  Heading,
  Text,
  View,
} from "@adobe/react-spectrum";
import React from "react";
import InfoIcon from "./InfoIcon";
import "../../styles/main.scss";

interface FormViewWithProps extends React.PropsWithChildren<{}> {
  title: string;
  body: React.ReactNode;
  footerInfo?: string;
  footerButtonComponent?: React.ReactNode;
  hipaa?: boolean;
}
const background = "gray-200";

// This is a generic form view component that can be used to render forms

const FormView: React.FC<FormViewWithProps> = ({
  children,
  hipaa,
  title,
  body,
  footerInfo,
  footerButtonComponent,
}) => {
  return (
    <Flex direction="column">
      <View
        borderWidth="thin"
        backgroundColor={hipaa ? background : "static-white"}
        borderColor="light"
        margin={"size-500"}
        borderRadius="medium"
        position="relative"
      >
        <View
          backgroundColor={hipaa ? background : "gray-100"}
          padding={"size-10"}
        >
          <Header height={"size-150"} margin={"size-200"}>
            <Heading level={4}>{title}</Heading>
          </Header>
        </View>
        <Divider size="S" marginStart={"size-0"} />
        <View margin={30}>
          <View marginBottom={50}>{body}</View>
        </View>
        <Divider size="S" marginStart={"size-200"} marginEnd={"size-200"} />
        <Footer>
          <Flex
            margin={"size-100"}
            alignItems="center"
            direction="row"
            justifyContent={"space-between"}
          >
            <Flex gap="size-50" marginStart={"size-200"}>
              <Flex alignItems="center">
                <InfoIcon />
              </Flex>
              <Text UNSAFE_className="footer_info">{footerInfo}</Text>
            </Flex>
            <Flex marginStart={30}>{footerButtonComponent}</Flex>
          </Flex>
        </Footer>
      </View>
    </Flex>
  );
};

export default FormView;
