import { IMenuItem } from "../../types/ISideNavbar";

const MarketoMeasureMenuData: IMenuItem[] = [
  {
    Solution: "Marketo Measure",
    LinkName: "Support Wiki",
    LinkURL:
      "https://wiki.corp.adobe.com/display/AMMCS/Adobe+Marketo+Measure+Support+Home",
    Contextual: "No",
    ContextualURLParameters: "",
    LinkDescription: "Marketo Measure Support Wiki",
    PointOfContact: "",
    DocumentationLink: "",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Marketo Measure",
    LinkName: "Admin Console",
    LinkURL:
      "https://agent.adminconsole.adobe.com/organizations/{orgId}/contracts",
    Contextual: "No",
    ContextualURLParameters: "",
    LinkDescription: "Admin Console link for Customer Org",
    PointOfContact: "KC Reynolds",
    DocumentationLink: "",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Marketo Measure",
    LinkName: "Marketo Measure ExL",
    LinkURL:
      "https://experienceleague.adobe.com/en/docs/marketo-measure/using/home",
    Contextual: "No",
    ContextualURLParameters: "",
    LinkDescription: "Experience League Home For Marketo Measure",
    PointOfContact: "KC Reynolds",
    DocumentationLink: "",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Marketo Measure",
    LinkName: "Marketo Measure Splunk",
    LinkURL: "https://splunk.or1.adobe.net/en-US/app/adobe_bizible/dashboards",
    Contextual: "No",
    ContextualURLParameters: "",
    LinkDescription: "Marketo Measure Splunk Dashboards",
    PointOfContact: "",
    DocumentationLink: "",
    LinkType: "External",
    ParameterType: "",
  },
];

export default MarketoMeasureMenuData;
