import React from "react";
import PageWrapper from "../components/common/PageWrapper";
import Dump from "../components/commerceSupportTools/Dump";

const DataDumpTool: React.FC = () => {
  document.title = "Data Dump Tool";
  return (
    <PageWrapper pageName={"Data Dump Tool"}>
      <Dump />
    </PageWrapper>
  );
};

export default DataDumpTool;
