import {
  Cell,
  Column,
  Row,
  TableBody,
  TableHeader,
  TableView,
  Text,
  Flex,
  View,
  Heading,
} from "@adobe/react-spectrum";
import { ToastQueue } from "@react-spectrum/toast";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import React from "react";
import { Button } from "@adobe/react-spectrum";
import ViewDetail from "@spectrum-icons/workflow/ViewDetail";
import { setAiArticle } from "../../../store/case/caseSlice";
import { useDispatch } from "react-redux";

function copyTextToClipboard(text: string) {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      ToastQueue.positive("Copied to clipboard", { timeout: 5000 });
    })
    .catch((err) => {
      ToastQueue.negative("Failed to copy to clipboard", { timeout: 5000 });
    });
}

function TableDataView(
  json: (string | { [p: string]: string })[],
  setIsViewList: (value: ((prevState: boolean) => boolean) | boolean) => void,
  setIsReview: (value: ((prevState: boolean) => boolean) | boolean) => void,
  contentMap: { [key: string]: string },
  getAiArticle: () => void,
): JSX.Element {
  const failsafe = <p>{JSON.stringify(json)}</p>;
  const dispatch = useDispatch();
  const viewArticle = (jiraKey: string) => {
    window.open("https://jira.corp.adobe.com/browse/" + jiraKey, "_blank");
  };

  try {
    if (!Array.isArray(json)) return failsafe;
    const columns = [...Object.keys(json[0])];
    const rows = json.map((entry) => Object.values(entry));

    if (!json.every((x) => typeof x === "object")) return failsafe;
    if (columns.length === 0) return failsafe;
    columns.push("Actions");

    return (
      <Flex direction="column">
        <TableView
          aria-label="Dynamic Table"
          selectionMode="single"
          selectionStyle="highlight"
          onAction={(key) => copyTextToClipboard(JSON.stringify(rows[+key]))}
          overflowMode="wrap"
          UNSAFE_className="oasis-data-table"
        >
          <TableHeader>
            {columns.map((key, index) => {
              return <Column key={`col-${key}`}>{key}</Column>;
            })}
          </TableHeader>
          <TableBody>
            {rows.map((row, i) => {
              return (
                <Row key={i}>
                  {[
                    ...row.map((cell, j) => (
                      <Cell key={`cell-${i}-${j + 1}`}>
                        <span
                          style={{ cursor: "text", WebkitUserSelect: "text" }}
                          onPointerDown={(e) => e.stopPropagation()}
                          onMouseDown={(e) => e.stopPropagation()}
                        >
                          <ReactMarkdown
                            remarkPlugins={[remarkGfm]}
                            allowedElements={["a", "br", "p", "em"]}
                            components={{
                              a: ({ node, children, ...props }) => {
                                if (props.href?.includes("mailto")) {
                                  return <>{children}</>;
                                }
                                if (props.href?.includes("http")) {
                                  props.target = "_blank";
                                  props.rel = "noopener noreferrer";
                                }
                                return <a {...props}>{children}</a>;
                              },
                            }}
                          >
                            {"" + cell}
                          </ReactMarkdown>
                        </span>
                      </Cell>
                    )),
                    <Cell>
                      <Button
                        variant="accent"
                        style={"outline"}
                        UNSAFE_className="blue-400"
                        onPress={() => viewArticle(row[0] as string)}
                      >
                        <ViewDetail />
                        <Text>View</Text>
                      </Button>
                    </Cell>,
                  ]}
                </Row>
              );
            })}
          </TableBody>
        </TableView>
        <View paddingY="size-1200" UNSAFE_style={{ textAlign: "center" }}>
          <Heading level={2}>For testing new article generation!</Heading>
          <Button
            variant="cta"
            onPress={(e) => {
              getAiArticle();
              setIsViewList(false);
              setIsReview(false);
            }}
          >
            Generate a New Article from this Case Details
          </Button>
        </View>
      </Flex>
    );
  } catch (error) {
    return failsafe;
  }
}

export default TableDataView;
