import React from "react";
import { View, TabList, Item, TabPanels, Tabs } from "@adobe/react-spectrum";
import DefectsTable from "./DefectsTable";

const DefectDashboard: React.FC = () => {
  return (
    <Tabs aria-label="Augmented Analysis Tabs">
      <TabList>
        <Item key="my-defects">My Defect Overview</Item>
        <Item key="all-defects">All Defects</Item>
      </TabList>
      <TabPanels marginTop="size-200">
        <Item key="my-defects">
          <View padding={"size-300"}></View>
          <DefectsTable defectType="getMyDefects" />
        </Item>
        <Item key="all-defects">
          <View padding={"size-300"}></View>
          <DefectsTable defectType="getAllDefects" />
        </Item>
      </TabPanels>
    </Tabs>
  );
};

export default DefectDashboard;
