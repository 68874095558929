import { micromark } from "micromark";
import { gfm, gfmHtml } from "micromark-extension-gfm";
import DOMPurify from "dompurify";

/**
 * Parses Markdown and sanitizes the resulting HTML string.
 *
 * @param markdown - The Markdown string to parse and sanitize.
 * @returns The sanitized HTML string.
 */
export function parseAndSanitizeMarkdown(markdown: string): string {
  const html = micromark(markdown, "utf-8", {
    extensions: [gfm()],
    htmlExtensions: [gfmHtml()],
  });
  return DOMPurify.sanitize(html);
}
