import React, { useEffect, useState } from "react";
import {
  Dialog,
  Button,
  TextField,
  DatePicker,
  TimeField,
  Flex,
  View,
  DialogContainer,
  Heading,
  Divider,
  Content,
  ButtonGroup,
  ActionButton,
  Form,
} from "@adobe/react-spectrum";
import CrossSmall from "@spectrum-icons/ui/CrossSmall";
import { CODE, NAME } from "../../config/BannerConfig";
import { useOktaAuth } from "@okta/okta-react";
import {
  usePostBannerDataMutation,
  useUpdateBannerDataMutation,
} from "../../services/supportInsights";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import {
  CalendarDate,
  parseDate,
  ZonedDateTime,
  parseZonedDateTime,
  DateValue,
  getLocalTimeZone,
  today,
  parseDateTime,
} from "@internationalized/date";
import { setBannerData } from "../../store/bannerSlice";
interface BannerFormProps {
  onClose: () => void;
  onCancel: () => void;
  closeModal: () => void;
}

const BannerForm: React.FC<BannerFormProps> = ({
  onClose,
  onCancel,
  closeModal,
}) => {
  const dispatch = useDispatch();
  const [message, setMessage] = React.useState("");
  const currentSystemTime = new Date().toISOString();
  const [startDate, setStartDate] = useState<DateValue | null>(null);
  const [endDate, setEndDate] = useState<DateValue | null>(null);
  const bannerData = useSelector((state: RootState) => state.banner.bannerData);

  const [errors, setErrors] = useState({
    message: "",
    startDate: "",
    endDate: "",
  });
  const [postBannerData, { isLoading, error }] = usePostBannerDataMutation();
  const [updateBannerData] = useUpdateBannerDataMutation();
  const { oktaAuth } = useOktaAuth();
  const formatDate = (date: Date | null): string => {
    if (!date) return "";
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const handleFormSubmit = () => {
    const newErrors = {
      message: "",
      startDate: "",
      endDate: "",
    };
    let hasError = false;

    if (!message.trim()) {
      newErrors.message =
        "Please provide a Banner Message of up to 100 characters.";
      hasError = true;
    }
    if (!startDate) {
      newErrors.startDate = "Please provide the Start Date";
      hasError = true;
    }
    if (!endDate) {
      newErrors.endDate = "Please provide the End Date";
      hasError = true;
    }
    if (startDate && endDate && startDate.compare(endDate) >= 0) {
      newErrors.startDate = "Start Date must be before End Date.";
      hasError = true;
    }
    if (startDate && endDate && endDate.compare(startDate) <= 0) {
      newErrors.endDate = "End Date must be after Start Date.";
      hasError = true;
    }

    setErrors(newErrors);
    if (!hasError) {
      handleSubmit();
    }
  };
  const handleSubmit = async () => {
    const formattedStartDate = startDate
      ? formatDate(new Date(startDate.toString()))
      : "";
    const formattedEndDate = endDate
      ? formatDate(new Date(endDate.toString()))
      : "";

    const dataObject = {
      name: NAME,
      code: CODE,
      data: {
        message: message,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      },
    };
    const token = oktaAuth.getAccessToken();
    if (!token) {
      return;
    }
    try {
      if (bannerData) {
        const upDatedResponse = await updateBannerData({
          id: bannerData.id,
          dataObject,
          token,
        }).unwrap();
        dispatch(setBannerData(upDatedResponse));
      } else {
        await postBannerData({ dataObject, token }).unwrap();
      }
      onClose();
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const getCurrentDateTime = () => {
    const now = new Date();
    return parseDateTime(
      `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, "0")}-${String(
        now.getDate(),
      ).padStart(2, "0")}T${String(now.getHours()).padStart(2, "0")}:${String(
        now.getMinutes(),
      ).padStart(2, "0")}`,
    );
  };
  useEffect(() => {
    if (bannerData) {
      setMessage(bannerData.data.message);
      const startDateTime = parseDateTime(
        bannerData.data.startDate.replace(" ", "T"),
      );
      const endDateTime = parseDateTime(
        bannerData.data.endDate.replace(" ", "T"),
      );
      setStartDate(startDateTime);
      setEndDate(endDateTime);
    }
  }, [bannerData]);
  const handleMessageChange = (value: string) => {
    if (value.length >= 100) {
      setErrors({
        ...errors,
        message: "Maximum limit of 100 characters reached",
      });
    } else {
      setErrors({ ...errors, message: "" });
    }
    setMessage(value);
  };

  return (
    <DialogContainer onDismiss={onClose}>
      <Dialog width="464px">
        <View position="absolute" top="0px" right="12px">
          <Button
            variant="secondary"
            onPress={closeModal}
            aria-label="Close"
            UNSAFE_style={{
              padding: 0,
              backgroundColor: "transparent",
              border: "none",
            }}
          >
            <CrossSmall />
          </Button>
        </View>
        <Heading>Banner Details</Heading>
        <Divider />
        <Content>
          <Form validationBehavior="native">
            <Flex direction="column" gap="size-200">
              <TextField
                isRequired
                label="Banner Message"
                value={message}
                onChange={handleMessageChange}
                validationState={errors.message ? "invalid" : undefined}
                width="100%"
                aria-label="Enter your banner message"
                maxLength={100}
                errorMessage={errors.message}
              />
              <DatePicker
                label="Start Date"
                value={startDate}
                granularity="minute"
                onChange={(value) => {
                  setStartDate(value);
                  setErrors({ ...errors, startDate: "" });
                }}
                validationState={errors.startDate ? "invalid" : undefined}
                hourCycle={24}
                hideTimeZone
                isRequired
                errorMessage={errors.startDate}
                minValue={getCurrentDateTime()}
              />
              <DatePicker
                label="End Date"
                value={endDate}
                hourCycle={24}
                granularity="minute"
                onChange={(value) => {
                  setEndDate(value);
                  setErrors({ ...errors, endDate: "" });
                }}
                validationState={errors.endDate ? "invalid" : undefined}
                hideTimeZone
                isRequired
                errorMessage={errors.endDate}
                minValue={startDate || getCurrentDateTime()}
              />
            </Flex>
            <div className="button-group">
              <ButtonGroup UNSAFE_style={{ marginTop: "30px" }}>
                <Button variant="secondary" onPress={closeModal}>
                  Cancel
                </Button>
                <Button variant="accent" onPress={handleFormSubmit}>
                  Submit
                </Button>
              </ButtonGroup>
            </div>
          </Form>
        </Content>
      </Dialog>
    </DialogContainer>
  );
};

export default BannerForm;
