import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface BannerState {
  bannerData: {
    id: number;
    name: string;
    code: string;
    data: {
      message: string;
      startDate: string;
      endDate: string;
    };
    created_at: string;
    updated_at: string;
  } | null;
}

const initialState: BannerState = {
  bannerData: null,
};

export const bannerSlice = createSlice({
  name: "banner",
  initialState,
  reducers: {
    setBannerData: (
      state,
      action: PayloadAction<BannerState["bannerData"]>,
    ) => {
      state.bannerData = action.payload;
    },
    clearBannerData: (state) => {
      state.bannerData = null;
    },
  },
});

export const { setBannerData, clearBannerData } = bannerSlice.actions;
export default bannerSlice.reducer;
