import { IMenuItem } from "../types/ISideNavbar";
import AdobeAnalyticsMenuData from "./products/adobeAnalytics";
import AdobeCampaignMenuData from "./products/adobeCampaign";
import AdobeExpManagerMenuData from "./products/adobeExpManager";
import AdobeExpPlatformMenuData from "./products/adobeExpPlatform";
import AllSolutionsMenuData from "./products/allSolutions";
import CommerceMenuData from "./products/commerce";
import MarketoMenuData from "./products/marketo";
import WorkfrontMenuData from "./products/workfront";
import MarketoMeasureMenuData from "./products/marketoMeasure";

const MenuData: IMenuItem[] = [
  ...AdobeAnalyticsMenuData,
  ...AdobeCampaignMenuData,
  ...AdobeExpManagerMenuData,
  ...AdobeExpPlatformMenuData,
  ...AllSolutionsMenuData,
  ...CommerceMenuData,
  ...MarketoMenuData,
  ...WorkfrontMenuData,
  ...MarketoMeasureMenuData,
];

export default MenuData;
