import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { TempAdmin } from "../types/tempAdmin";
import { Case } from "../types/case";

interface UserData {
  id: number;
  project: string;
  environment: string;
  email: string;
  password: string;
  username: string;
  ticket: string;
  adminUrl: string;
  adminUri: string;
}

// create tempAdminApi
export const tempAdminApi = createApi({
  reducerPath: "tempAdminApi",
  baseQuery: fetchBaseQuery({
    baseUrl: window.env.REACT_APP_DUMP_TOOL_API_US,
    credentials: "same-origin",
    timeout: 3000,
  }),
  endpoints: (builder) => ({
    getTempAdmin: builder.query<
      UserData,
      {
        projectId: string;
        environment: string;
        currentUserEmail: string;
      }
    >({
      query: ({ projectId, environment, currentUserEmail }) =>
        `/api/v1/admin-users/${projectId}/${environment}/${currentUserEmail}`,
    }),
    createTempAdmin: builder.mutation<
      TempAdmin,
      {
        projectId: string;
        environment: string;
        currentUserEmail: string;
        ticketId: string;
        rengaId: string;
        orgId: string;
      }
    >({
      query: ({
        projectId,
        environment,
        currentUserEmail,
        ticketId,
        rengaId,
        orgId,
      }) => ({
        url: `/api/v1/admin-users/${projectId}/${environment}/${currentUserEmail}/${ticketId}/${orgId}/${rengaId}`,
        method: "POST",
      }),
    }),
    deleteTempAdmin: builder.mutation<
      TempAdmin,
      {
        projectId: string;
        environment: string;
        currentUserEmail: string;
        ticketId: string;
      }
    >({
      query: ({ projectId, environment, currentUserEmail, ticketId }) => ({
        url: `/api/v1/admin-users/${projectId}/${environment}/${currentUserEmail}/${ticketId}`,
        method: "DELETE",
      }),
    }),
    deleteTempAdminAll: builder.mutation<
      TempAdmin,
      {
        projectId: string;
        environment: string;
        currentUserEmail: string;
        ticketId: string;
      }
    >({
      query: ({ projectId, environment, ticketId }) => ({
        url: `/api/v1/admin-users-all/${projectId}/${environment}/${ticketId}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetTempAdminQuery,
  useCreateTempAdminMutation,
  useDeleteTempAdminMutation,
  useDeleteTempAdminAllMutation,
} = tempAdminApi;
